import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IProfile, IProfileModule } from './types';
import { listProfilesRoute, editProfileRoute } from '../../routes/config';
import { profilesRouteApi } from '../../routes/config/api';
import { useRequest } from '../../hooks/RequestContext';
import isBlank from '../../utils/isBlank';

import LayoutShow from '../../components/Layouts/Show';
import InputRead from '../../components/Input/Read';
import CheckboxRead from '../../components/Input/CheckboxRead';
import Table from '../../components/SimpleTable';

import { Container } from './styles';

interface IShowParams {
  id: string;
}

interface IProfileModulesList {
  profileModules: IProfileModule[];
}

const ProfileModulesList: React.FC<IProfileModulesList> = ({
  profileModules
}) => (
  <div className="table-container">
    <Table
      title="Módulos vinculados ao perfil"
      columns={[
        {
          Header: 'Código',
          accessor: 'id',
          width: 100,
        },
        { Header: 'Nome', accessor: 'name', minWidth: 300 },
      ]}
      fetch={{
        fetchData: () => {
          const formattedData = profileModules.map((profileModule) => ({
            id: String(profileModule.module.id).padStart(6, '0'),
            name: profileModule.module.name,
          }));

          return Promise.resolve(formattedData);
        }
      }}
      actions={{ enable: false }}
    />
  </div>
);

export const Show: React.FC = () => {
  const [profile, setProfile] = useState<IProfile>({} as IProfile);
  const [loading, setLoading] = useState(true);
  const { id: userId } = useParams<IShowParams>();
  const { get: requestGet } = useRequest();

  useEffect(() => {
    async function findUser() {
      try {
        const response = await requestGet(`${profilesRouteApi.path}/${userId}`);
        if (!isBlank(response)) {
          setProfile(response);
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findUser();
  }, [requestGet, userId]);

  return (
    <Container>
      <LayoutShow
        breadcrumbs={[
          { text: 'Perfis', path: listProfilesRoute.path },
          { text: 'Visualizar Perfil' }
        ]}
        navMenuProps={{
          deleteRoute: {
            route: `${profilesRouteApi.path}/${userId}`,
            redirect: listProfilesRoute.path,
          },
          editRoute: editProfileRoute.build({ id: userId }),
        }}
        cardProps={{
          title: 'Visão geral do perfil',
          noData: isBlank(profile),
          loading,
        }}
      >
        <InputRead label="Nome" value={profile.name} />

        <CheckboxRead
          label="Gerencia usuários"
          value={profile.can_manage_users}
          xs={6}
        />
        <CheckboxRead
          label="Gerencia módulos"
          value={profile.can_manage_modules}
          xs={6}
        />
        <CheckboxRead
          label="Gerencia perfis"
          value={profile.can_manage_profiles}
          xs={6}
        />
        <CheckboxRead
          label="Gerencia provedores de dados"
          value={profile.can_manage_bi_providers}
          xs={6}
        />
      </LayoutShow>

      {!isBlank(profile) && (
        <ProfileModulesList profileModules={profile.profile_modules || []} />
      )}
    </Container>
  );
}
