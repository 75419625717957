import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 512px;

    img {
      width: 100%;
      height: 288px;
    }

    caption {
      color: ${props => props.theme.colors.text.cream};
      font-size: 0.85rem;
    }
  }
`;
