import React from "react";
import { Switch } from "react-router-dom";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import { Create, Edit, List, Show } from "../../pages/Companies";

export const createCompanyRoute = new Route("/empresas/novo");

export const editCompanyRoute = new Route("/empresas/:id/editar");

export const listCompaniesRoute = new Route("/empresas");

export const showCompanyRoute = new Route("/empresas/:id/visualizar");

export const CompaniesRouteComponent: React.FC = () => (
  <Switch>
    <RouteWrapper
      exact
      isPrivate
      path={createCompanyRoute.path}
      component={Create}
    />
    <RouteWrapper exact isPrivate path={editCompanyRoute.path} component={Edit} />
    <RouteWrapper exact isPrivate path={listCompaniesRoute.path} component={List} />
    <RouteWrapper exact isPrivate path={showCompanyRoute.path} component={Show} />

    <RouteWrapper
      isPrivate
      redirectIfNotExact
      path={listCompaniesRoute.path}
      component={List}
    />
  </Switch>
);
