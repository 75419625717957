import React from 'react';

import { Container, IMetric } from './styles';

export interface IMetricsProps {
  xs?: IMetric;
  sm?: IMetric;
  md?: IMetric;
}

export interface IGridProps extends IMetricsProps {
  spacing?: number;
  isItem?: boolean;
  isContainer?: boolean;
  className?: string | undefined;
};

const Grid: React.FC<IGridProps> = ({
  spacing = 0,
  isContainer = false,
  isItem = false,
  className,
  xs = 12,
  sm = 12,
  md = 12,
  children,
}) => {
  return (
    <Container
      spacing={spacing}
      isContainer={isContainer}
      isItem={isItem}
      className={className}
      xs={xs}
      sm={sm}
      md={md}
    >
      {children}
    </Container>
  );
}

export default Grid;
