import React from 'react';
import ReactLoading from 'react-loading';

import Grid from '../Grid';

import { Container } from './styles';

export interface ICardFormProps {
  title: string;
  noData: boolean;
  loading: boolean;
}

interface IContentProps {
  noData: boolean;
  loading: boolean;
}

const Content: React.FC<IContentProps> = ({ noData, loading, children }) => {
  if (loading) {
    return (
      <div className="loading">
        <ReactLoading type="bars" className="spinner" width={38} />
      </div>
    );
  }

  if (noData && !loading) {
    return (
      <div className="no-data">
        <span>Ops, nenhuma informação encontrada :(</span>
      </div>
    )
  }

  return <>{children}</>;
}

const CardForm: React.FC<ICardFormProps> = ({ title, ...restData }) => {
  return (
    <Container>
      <Grid isContainer spacing={6} className="header">
        <h4>{title}</h4>
      </Grid>
      <Grid isContainer spacing={4}>
        <Content {...restData} />
      </Grid>
    </Container>
  );
}

export default CardForm;
