import React, { useEffect } from "react";

import NavMenu, { INavMenuProps } from "../../NavMenu";
import Breadcrumbs from "../../Breadcrumbs";

import {
  useBreadcrumbs,
  BreadcrumbProps,
} from "../../../hooks/BreadcrumbsContext";
import { dashboardRoute } from "../../../routes/config";

import { Container, Content } from "./styles";

export interface IBaseLayoutProps {
  breadcrumbs?: BreadcrumbProps[];
  navMenuProps: INavMenuProps;
}

const Base: React.FC<IBaseLayoutProps> = ({
  breadcrumbs = [],
  navMenuProps,
  children,
}) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      { path: dashboardRoute.path, text: "Início" },
      ...breadcrumbs,
    ]);
  }, [setBreadcrumbs, breadcrumbs]);

  return (
    <Container>
      <Breadcrumbs />
      <NavMenu {...navMenuProps} />

      <Content>
        {children}
      </Content>
    </Container>
  );
};

export default Base;
