import styled from 'styled-components';

export const Grid = styled.div`
  width: 16.66%;
  padding: 8px;

  @media(max-width: 1350px) {
    width: 20%;
  }

  @media(max-width: 1200px) {
    width: 25%;
  }

  @media(max-width: 900px) {
    width: 33.33%;
  }

  @media(max-width: 650px) {
    width: 50%;

    a {
      .image {
        min-height: 200px;
      }
    }
  }

  @media(max-width: 600px) {
    a {
      .image {
        min-height: 190px;
      }
    }
  }

  @media(max-width: 550px) {
    a {
      .image {
        min-height: 180px;
      }
    }
  }

  @media(max-width: 500px) {
    a {
      .image {
        min-height: 170px;
      }
    }
  }

  @media(max-width: 470px) {
    a {
      .image {
        min-height: 160px;
      }
    }
  }

  @media(max-width: 440px) {
    width: 100%;

    a {
      .image {
        min-height: 230px;
      }
    }
  }

  @media(max-width: 400px) {
    a {
      .image {
        min-height: 220px;
      }
    }
  }

  @media(max-width: 360px) {
    a {
      .image {
        min-height: 210px;
      }
    }
  }

  @media(max-width: 340px) {
    a {
      .image {
        min-height: 200px;
      }
    }
  }

  @media(max-width: 320px) {
    a {
      .image {
        min-height: 180px;
      }
    }
  }

  @media(max-width: 300px) {
    a {
      .image {
        min-height: 160px;
      }
    }
  }
`;

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%), 0 -1px 2px 0 rgb(0 0 0 / 5%);
  transition: transform 90ms ease-in-out;
  overflow: hidden;

  &:hover {
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 15%), 0 -1px 0px 0 rgb(0 0 0 / 5%);
    transform: scale(1.05);
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.text.title};
    border-radius: 4px;

    .image {
      height: 155px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .description {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      padding: 10px 10px 12px 10px;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 0.8rem;
        line-height: 18px;
      }
    }
  }
`;
