import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import jsonFormData from 'json-form-data';

import { IModule } from './types';
import { useRequest } from '../../hooks/RequestContext';
import { useAuth } from '../../hooks/AuthContext';
import { modulesRouteApi } from '../../routes/config/api';
import { listModulesRoute, showModuleRoute } from '../../routes/config';
import isBlank from '../../utils/isBlank';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';
import { isRootUser } from '../../utils/userTypes';

import LayoutEdit from '../../components/Layouts/Edit';
import Form from './Form';

interface IEditParams {
  id: string;
}

export const Edit: React.FC = () => {
  const [initialData, setInitialData] = useState<IModule>({} as IModule);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [powerbiModuleId, setPowerbiModuleId] = useState();
  const formRef = useRef<FormHandles>(null);
  const { id: moduleId } = useParams<IEditParams>();
  const history = useHistory();
  const { get: requestGet, put: requestPut } = useRequest();
  const { user } = useAuth();

  const updateModule = useCallback(
    async (data: any) => {
      try {
        setIsSaving(true);

        const formData = jsonFormData({
          bi_provider_id: data.bi_provider,
          name: data.name,
          file: data.image || undefined,
          powerbi_module: {
            ...data.powerbi_module,
            id: powerbiModuleId,
          },
          shared_with_companies: data.shared_modules,
        });
        const response = await requestPut(
          `${modulesRouteApi.path}/${moduleId}`,
          formData
        );

        toast.success("Módulo atualizado com sucesso!");
        history.push(showModuleRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível atualizar o módulo!");
      } finally {
        setIsSaving(false);
      }
    },
    [powerbiModuleId, requestPut, moduleId, history]
  );

  const handleSubmit = useCallback(
    async () => {
      try {
        formRef.current?.setErrors({});

        const data = formRef.current?.getData() as any;
        const datasetSchema = !!data.powerbi_module?.identities
          ? Yup.string()
          : Yup.string().required('Campo obrigatório');
        const schema = Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          bi_provider: Yup.mixed().required("Campo obrigatório"),
          powerbi_module: Yup.object().shape({
            group_id: Yup.string().required('Campo obrigatório'),
            report_id: Yup.string().required('Campo obrigatório'),
            dataset_id: datasetSchema,
          }),
        });

        await schema.validate(data, { abortEarly: false });

        updateModule(data);
      } catch (err) {
        const validationErrors = getValidationsErrors(err);
        formRef.current?.setErrors(validationErrors);
      }
    },
    [updateModule]
  );

  useEffect(() => {
    async function findModule() {
      try {
        const modulesResponse = await requestGet(`${modulesRouteApi.path}/${moduleId}`);
        const sharedModulesWithCompanies = [];

        if (isRootUser(user.type)) {
          const sharedModulesesponse = await requestGet(
            `${modulesRouteApi.path}/shared-with-companies/${moduleId}?per_page=10000`
          );

          sharedModulesWithCompanies.push(...sharedModulesesponse.data);
        }

        if (!isBlank(modulesResponse)) {
          const sharedModules = sharedModulesWithCompanies.map((sharedModule) => ({
            label: sharedModule.company.company_name,
            value: sharedModule.company.id,
          }));

          setInitialData({
            ...modulesResponse,
            image: modulesResponse.image_url,
            bi_provider: {
              label: modulesResponse.bi_provider?.name,
              value: modulesResponse.bi_provider?.id,
            },
            profile: {
              label: modulesResponse.profile?.name,
              value: modulesResponse.profile?.id,
            },
            shared_modules: sharedModules,
          });
          setPowerbiModuleId(modulesResponse.powerbi_module.id);
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findModule();
  }, [requestGet, user, moduleId]);

  return (
    <LayoutEdit
      breadcrumbs={[
        { text: 'Módulos', path: listModulesRoute.path },
        { text: 'Editar Módulo' }
      ]}
      cardProps={{
        title: 'Alterar informações do módulo',
        noData: isBlank(initialData),
        loading,
      }}
      footerActionsProps={{
        onCancelRoute: listModulesRoute.path,
        onSubmit: handleSubmit,
        loading: isSaving || loading,
      }}
    >
      <Form formRef={formRef} initialData={initialData} />
    </LayoutEdit>
  );
}
