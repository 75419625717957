import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useBreadcrumbs,
  BreadcrumbProps,
  HTMLAnchorElementEvent,
} from "../../hooks/BreadcrumbsContext";

import { Container, Link, Typography, Separator } from './styles';

interface IBreadcrumbs {
  className?: string | undefined;
}

const Breadcrumbs: React.FC<IBreadcrumbs> = ({ className }) => {
  const { breadcrumbs } = useBreadcrumbs();
  const history = useHistory();

  const handleClick = useCallback(
    (event: HTMLAnchorElementEvent, breadcrumb: BreadcrumbProps) => {
      if (breadcrumb.onClick) {
        breadcrumb.onClick(event, breadcrumb);
      } else {
        event.preventDefault();
        history.push(breadcrumb.path || "");
      }
    },
    [history]
  );

  if (!breadcrumbs?.length) return <></>;

  return (
    <Container>
      {breadcrumbs.map((breadcrumb, idx) => {
        const isLastBreadcrumb = idx === breadcrumbs.length - 1;
        const key = `${idx}-${breadcrumb.text}`;

        if (isLastBreadcrumb) {
          return <Typography key={key}>{breadcrumb.text}</Typography>;
        }

        return (
          <div key={key} className={`link ${className || ''}`}>
            <Link
              to={breadcrumb.path || ''}
              onClick={(event: HTMLAnchorElementEvent): void => {
                handleClick(event, breadcrumb);
              }}
            >
              {breadcrumb.text}
            </Link>
            <Separator>/</Separator>
          </div>
        );
      })}
    </Container>
  );
}

export default Breadcrumbs;
