const userTypes = {
  ROOT: 'root',
  OWNER: 'owner',
  USER: 'user',
};

export function isRootUser(type: string) {
  return userTypes.ROOT === type;
}

export function isOwnerUser(type: string) {
  return userTypes.OWNER === type;
}

export function isCommomUser(type: string) {
  return userTypes.USER === type;
}
