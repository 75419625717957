import React, {
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
  useRef
} from 'react';
import { useField } from "@unform/core";
import { MdModeEdit } from 'react-icons/md';

import Grid from "../../../components/Grid";
import defaultImageUrl from '../../../assets/images/upload-module-image.svg';

import { Container } from './styles';

const ModuleImage: React.FC = () => {
  const [file, setFile] = useState<FileList>();
  const { fieldName, defaultValue, registerField } = useField('image');
  const [imageUrl, setImageUrl] = useState(defaultValue || defaultImageUrl);
  const inputRef = useRef<any>(null);

  const handleChangeImage = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const [selectedFile] = e.target.files as any;
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageData = e.target?.result as any;
        setImageUrl(imageData);
      };

      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => file,
      setValue: (_, newValue) => {
        setImageUrl(newValue || defaultImageUrl);
      },
    });
  }, [fieldName, registerField, file, setImageUrl]);

  return (
    <Grid isItem xs={6} spacing={2}>
      <Container>
        <div className="content">
          <img
            src={imageUrl}
            alt="Imagem do módulo"
          />

          <label htmlFor="image">
            <MdModeEdit size={18} />
            <input
              id="image"
              type="file"
              accept="image/*"
              onChange={handleChangeImage}
            />
          </label>
          <span className="description">
            Adicione uma imagem. Recomendamos utilizar imagens na resolução de <b>1280x720</b>
          </span>
        </div>
      </Container>
    </Grid>
  );
}

export default ModuleImage;
