import styled from 'styled-components';
import { Form as UForm } from '@unform/web';

import { TUserType } from '../../utils/getUserType';

interface IUserType {
  type: TUserType;
}

export const UserType = styled.div<IUserType>`
  display: flex;
  justify-content: center;
  width: 120px;
  padding: 4px;
  border-radius: 2px;
  border: 1px solid ${
    props => {
      switch(props.type) {
        case 'root':
          return props.theme.colors.green;
        case 'owner':
          return props.theme.colors.primary;
        default:
          return props.theme.colors.blue;
      }
    }
  };
  font-size: 0.85rem;
  font-weight: 500;
  color: ${
    props => {
      switch(props.type) {
        case 'root':
          return props.theme.colors.green;
        case 'owner':
          return props.theme.colors.primary;
        default:
          return props.theme.colors.blue;
      }
    }
  };
`;

export const Form = styled(UForm)`
  width: 100%;
`;
