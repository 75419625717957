import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  DashboardRouteComponent,
  SignInRouteComponent,
  BIProvidersRouteComponent,
  UserProfileRouteComponent,
  UsersRouteComponent,
  ModulesRouteComponent,
  ProfilesRouteComponent,
  CompaniesRouteComponent,
  MyCompanyRouteComponent,
  signInRoute,
  dashboardRoute,
  userProfileRoute,
  listBIProvidersRoute,
  listUsersRoute,
  listModulesRoute,
  listProfilesRoute,
  listCompaniesRoute,
  myCompanyRoute,
} from "./config";
import { useAuth } from '../hooks/AuthContext';
import { RequestProvider } from '../hooks/RequestContext';
import { ProfileModulesProvider, useProfileModules } from '../hooks/ProfileModulesContext';
import { BreadcrumbsProvider } from '../hooks/BreadcrumbsContext';

import DrawerApp from '../components/DrawerApp';
import RouteWrapper from './RouteWrapper';
import EmbeddedDashboard from '../pages/EmbeddedDashboard';

import { Main, ContainerLoading, AnimatedLoading } from "./styles";

const PublicRoutes: React.FC = () => (
  <Main>
    <Switch>
      <Route exact path={signInRoute.path} component={SignInRouteComponent} />
      <Redirect to={signInRoute.path} />
    </Switch>
  </Main>
);

const PrivateRoutes: React.FC = () => {
  const {
    loading: searchingProfileModules,
    modulesPath,
    adminModulesPath
  } = useProfileModules();

  if (searchingProfileModules) {
    return (
      <ContainerLoading>
        <AnimatedLoading />
      </ContainerLoading>
    );
  }

  return (
    <Switch>
      {modulesPath.map((modulePath, key) => (
        <RouteWrapper
          isPrivate
          exact
          key={`${modulePath}/${key}`}
          path={modulePath}
          component={EmbeddedDashboard}
        />
      ))}

      <DrawerApp>
        <Main>
          <Switch>
            <Route path={dashboardRoute.path} exact>
              <DashboardRouteComponent />
            </Route>
            <Route path={userProfileRoute.path} exact>
              <UserProfileRouteComponent />
            </Route>

            {adminModulesPath.map((modulePath, key) => {
              if (modulePath === listCompaniesRoute.path) {
                return (
                  <Route key={`${modulePath}/${key}`} path={listCompaniesRoute.path}>
                    <CompaniesRouteComponent />
                  </Route>
                );
              }

              if (modulePath === listBIProvidersRoute.path) {
                return (
                  <Route key={`${modulePath}/${key}`} path={listBIProvidersRoute.path}>
                    <BIProvidersRouteComponent />
                  </Route>
                );
              }

              if (modulePath === listUsersRoute.path) {
                return (
                  <Route key={`${modulePath}/${key}`} path={listUsersRoute.path}>
                    <UsersRouteComponent />
                  </Route>
                );
              }

              if (modulePath === listModulesRoute.path) {
                return (
                  <Route key={`${modulePath}/${key}`} path={listModulesRoute.path}>
                    <ModulesRouteComponent />
                  </Route>
                );
              }

              if (modulePath === listProfilesRoute.path) {
                return (
                  <Route key={`${modulePath}/${key}`} path={listProfilesRoute.path}>
                    <ProfilesRouteComponent />
                  </Route>
                );
              }

              if (modulePath === myCompanyRoute.path) {
                return (
                  <Route key={`${modulePath}/${key}`} path={myCompanyRoute.path}>
                    <MyCompanyRouteComponent />
                  </Route>
                );
              }

              return <></>;
            })}

            <Route>
              <DashboardRouteComponent />
            </Route>
          </Switch>
        </Main>
      </DrawerApp>
    </Switch>
  );
}

const Routes: React.FC = () => {
  const { signed } = useAuth();

  if (!signed) {
    return <PublicRoutes />;
  }

  return (
    <RequestProvider>
      <ProfileModulesProvider>
        <BreadcrumbsProvider>
          <PrivateRoutes />
        </BreadcrumbsProvider>
      </ProfileModulesProvider>
    </RequestProvider>
  );
};

export default Routes;
