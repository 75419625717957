import styled, { css } from 'styled-components';

export type IMetric =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

export interface IMetricsProps {
  xs: IMetric;
  sm: IMetric;
  md: IMetric;
}

export interface IContainer extends IMetricsProps {
  spacing: number;
  isItem: boolean;
  isContainer: boolean;
}

function getWidthPercentage(spacing: number): number {
  if (spacing === 12 || isNaN(spacing)) return 100;

  return (100 / 12) * spacing;
}

function getMetric(metric: number): number {
  const baseMetricValue = 4;

  if (metric === 12 || isNaN(metric)) return 100;

  return metric * baseMetricValue;
}

const containerCss = css<IContainer>`
  display: flex;
  flex-wrap: wrap;
  width: ${props => getWidthPercentage(props.xs)}%;
  padding: ${props => getMetric(props.spacing)}px;

  @media(max-width: 800px) {
    width: ${props => getWidthPercentage(props.md)}%;
  }

  @media(max-width: 600px) {
    width: ${props => getWidthPercentage(props.sm)}%;
  }
`;

const itemCss = css<IContainer>`
  width: ${props => getWidthPercentage(props.xs)}%;
  padding: ${props => getMetric(props.spacing)}px;

  @media(max-width: 800px) {
    width: ${props => getWidthPercentage(props.md)}%;
  }

  @media(max-width: 600px) {
    width: ${props => getWidthPercentage(props.sm)}%;
  }
`;

export const Container = styled.div<IContainer>`
  ${props => props.isContainer && containerCss};
  ${props => props.isItem && itemCss};
`;
