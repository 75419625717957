export type TUserType = 'root' | 'owner' | 'user';

export default function getUserType(type: TUserType): string {
  switch(type) {
    case 'root':
      return 'Root';
    case 'owner':
      return 'Proprietário';
    default:
      return 'Usuário';
  }
};
