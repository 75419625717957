import React from 'react';
import { RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri';

import Grid, { IGridProps } from '../../Grid';

import { Container } from './styles';

interface ICheckboxReadProps
  extends Omit<IGridProps, 'isContainer' | 'isItem' | 'className'> {
  label: string;
  value: boolean;
  gridClassName?: string | undefined;
}

const CheckboxRead: React.FC<ICheckboxReadProps> = ({
  label,
  value,
  xs,
  sm,
  md,
  spacing = 2,
  gridClassName,
}) => {
  return (
    <Grid
      isItem
      spacing={spacing}
      xs={xs}
      md={md}
      sm={sm}
      className={gridClassName}
    >
      <Container isSelected={value}>
        <div>
          <button>
            {value
              ? <RiCheckboxLine size={22} />
              : <RiCheckboxBlankLine size={22} />
            }
          </button>
        </div>
        <span>{label}</span>
      </Container>
    </Grid>
  );
}

export default CheckboxRead;
