import React from 'react';

import { profilesRouteApi } from '../../routes/config/api';
import {
  showProfileRoute,
  editProfileRoute,
  createProfileRoute
} from '../../routes/config';

import LayoutList from '../../components/Layouts/List';

import { ProfilePermission } from './styles';

interface IPermission {
  hasPermission: boolean;
}

const Permission: React.FC<IPermission> = ({ hasPermission }) => (
  <ProfilePermission hasPermission={hasPermission}>
    <span>{hasPermission ? 'Sim' : 'Não'}</span>
  </ProfilePermission>
);

export const List: React.FC = () => {
  return (
    <LayoutList
      breadcrumbs={[{ text: 'Perfis' }]}
      navMenuProps={{
        visible: true,
        newRoute: createProfileRoute.path,
      }}
      tableProps={{
        title: 'Listagem de Perfis',
        actions: {
          enable: true,
          showRoute: showProfileRoute,
          editRoute: editProfileRoute,
          deleteRoute: profilesRouteApi.path,
        },
        columns: [
          { Header: 'Nome', accessor: 'name', minWidth: 300 },
          {
            Header: 'Gerencia Usuários',
            accessor: 'can_manage_users',
            minWidth: 205,
            Cell({ row }) {
              return <Permission hasPermission={row.values.can_manage_users} />;
            }
          },
          {
            Header: 'Gerencia Módulos',
            accessor: 'can_manage_modules',
            minWidth: 205,
            Cell({ row }) {
              return <Permission hasPermission={row.values.can_manage_modules} />;
            }
          },
          {
            Header: 'Gerencia Perfis',
            accessor: 'can_manage_profiles',
            minWidth: 180,
            Cell({ row }) {
              return <Permission hasPermission={row.values.can_manage_profiles} />;
            }
          },
          {
            Header: 'Gerencia Provedores de Dados',
            accessor: 'can_manage_bi_providers',
            minWidth: 310,
            Cell({ row }) {
              return <Permission hasPermission={row.values.can_manage_bi_providers} />;
            }
          },
        ],
        fetch: {
          url: profilesRouteApi.path,
        }
      }}
    />
  );
}
