import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  .breadcrumbs {
    margin-bottom: 16px;
  }
`;
