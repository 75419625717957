import React from "react";
import { Switch } from "react-router-dom";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import { Create, Edit, List, Show } from "../../pages/Users";

export const createUserRoute = new Route("/usuarios/novo");

export const editUserRoute = new Route("/usuarios/:id/editar");

export const listUsersRoute = new Route("/usuarios");

export const showUserRoute = new Route("/usuarios/:id/visualizar");

export const UsersRouteComponent: React.FC = () => (
  <Switch>
    <RouteWrapper
      exact
      isPrivate
      path={createUserRoute.path}
      component={Create}
    />
    <RouteWrapper exact isPrivate path={editUserRoute.path} component={Edit} />
    <RouteWrapper exact isPrivate path={listUsersRoute.path} component={List} />
    <RouteWrapper exact isPrivate path={showUserRoute.path} component={Show} />

    <RouteWrapper
      isPrivate
      redirectIfNotExact
      path={listUsersRoute.path}
      component={List}
    />
  </Switch>
);
