import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 512px;
    position: relative;

    img {
      width: 100%;
      height: 288px;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 42px;
      height: 42px;
      background-color: ${(props) => props.theme.colors.primary};
      border: none;
      border-radius: 50%;
      top: 8px;
      right: 8px;
      cursor: pointer;
      transition: background-color 200ms;

      input {
        display: none;
      }

      svg {
        width: 20px;
        height: 20px;
        color: ${(props) => props.theme.colors.white};
      }

      &:hover {
        background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
      }
    }

    .description {
      display: inline-block;
      text-align: center;
      width: 90%;
      margin-top: 8px;
    }
  }
`;
