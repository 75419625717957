import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  min-width: 0;
  box-shadow: 0 0 2rem 0 rgba(136,152,170,0.15);
  word-wrap: break-word;
  background-clip: initial;
  border: 1px solid ${props => props.theme.colors.black_050};
  border-radius: 4px;
  padding-bottom: 16px;

  .loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 4px 0;

    .spinner {
      height: 40px !important;
      fill: ${props => props.theme.colors.primary} !important;
    }
  }
`;

export const Header = styled.div`
  padding: 1.2rem 1.5rem;
  margin-bottom: 0;
  background-color: ${props => props.theme.colors.white};
  font-size: 1.1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
