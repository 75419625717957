import styled from 'styled-components';
// @ts-ignore
import { MenuItem as SZHMenuItem } from '@szhsin/react-menu';
import { transparentize } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 1.5rem;
  font-size: 0.8rem;

  @media(max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const RowsPerPage = styled.div`
  display: flex;
  align-items: center;

  .select-button {
    display: flex;
    align-items: center;
    margin-left: 24px;
    cursor: pointer;

    svg {
      margin-left: 2px;
    }
  }

  @media(max-width: 600px) {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const MenuItem = styled(SZHMenuItem)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text.title};
  font-size: 0.8rem;
  padding: 8px 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }

  &:active {
    -webkit-tap-highlight-color: transparent;
    background-color: ${
      props => transparentize(0.84, props.theme.colors.primary)
    };

    svg, span {
      color: ${ props => props.theme.colors.primary};
      font-weight: 500;
    }
  }

  ${props => props.disabled && `
    pointer-events: none;
    opacity: 0.4;
    font-weight: 500;
  `}
`;

export const Totalizer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 2px 48px 0;

  @media(max-width: 600px) {
    justify-content: flex-start;
    padding: 2px 8px 0 0;
  }
`;

export const Paginator = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 30px;
    height: 30px;
    background-color: transparent;
    color: ${props => props.theme.colors.text.title};
    border-radius: 50%;
    transition: background-color 200ms ease-in-out;

    &:hover:not(:disabled) {
      background-color: ${props => props.theme.colors.black_100};
    }

    &:disabled {
      color: ${props => props.theme.colors.text.p};
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
`;
