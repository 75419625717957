import React, { useState, useEffect, useCallback } from 'react';
import { useTable, useFlexLayout, Column } from 'react-table';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';

import { useRequest } from '../../hooks/RequestContext';

import ResponsiveTable from './ResponsiveTable';
import Actions, { IActions } from './ResponsiveTable/Actions';

import { Container, Header } from './styles';

interface IFetch {
  url?: string;
  fetchData?: (data: any) => Promise<any[]>;
}

export interface ITableProps {
  title: string;
  columns: Column[];
  fetch: IFetch;
  actions: Omit<IActions, 'row'>;
}

const SimpleTable: React.FC<ITableProps> = ({ title, columns, fetch, actions }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const tableColumns: any = React.useMemo(
    () => {
      const actionsColumn: Column = {
        Header: 'Ações',
        accessor: 'actions',
        width: 150,
        Cell: ({ row }) => <Actions {...actions} row={row} refreshData={findData} />,
      };

      return actions.enable ? [...columns, actionsColumn] : columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, columns],
  );
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    getTableBodyProps,
  } = useTable(
    {
      columns: tableColumns,
      data,
    },
    useFlexLayout,
  );
  const { get: requestGet } = useRequest();

  const findData = useCallback(async () => {
    try {
      setLoading(true);

      const requestMethod = fetch.fetchData || requestGet;
      const response = await requestMethod(fetch.url || '');

      setData(response);
    } catch {
      toast.error('Ops, ocorreu um problema ao buscar as informações!');
    } finally {
      setLoading(false);
    }
  }, [requestGet, fetch]);

  useEffect(() => {
    findData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Header>
        <h4>{title}</h4>
      </Header>
      <ResponsiveTable
        tableProps={getTableProps()}
        headerGroups={headerGroups}
        rows={rows}
        tableBodyProps={getTableBodyProps()}
        prepareRow={prepareRow}
        enableActions={actions.enable}
        isLoading={loading}
      />

      {loading && (
        <div className="loading">
          <ReactLoading type="bars" className="spinner" width={38} />
        </div>
      )}
    </Container>
  );
}

export default SimpleTable;
