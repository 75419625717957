import styled from "styled-components";
import { transparentize } from 'polished';

export const Container = styled.div`
  width: 100%;
  padding: 0px;

  .powerbi-report-embed {
    height: 100vh;
    border: none;

    iframe {
      border: none;
    }
  }
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;

  h3 {
    margin-top: 30px;
    font-size: 1rem;
  }

  span {
    font-size: 0.8rem;
    margin-top: 8px;
  }

  button {
    border: none;
    margin-top: 32px;
    background-color: transparent;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    font-size: 0.9rem;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: ${
        props => transparentize(0.86, props.theme.colors.primary)
      };
    }
  }
`;
