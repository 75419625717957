import React from 'react';
import { FormHandles, Scope } from '@unform/core';

import { ICompany } from '../types';

import Grid from '../../../components/Grid';
import InputText from '../../../components/Input/Text';

import { Form } from '../styles';

interface IFormProps {
  initialData?: ICompany;
  isEditPage?: boolean;
  formRef: React.RefObject<FormHandles>;
}

const CustomForm: React.FC<IFormProps> = ({
  formRef,
  isEditPage = false,
  initialData,
}) => {
  React.useEffect(() => {
    if (initialData) {
      formRef.current?.setData(initialData);
    }
  }, [initialData, formRef]);

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      {!isEditPage && <h4>Dados da empresa</h4>}

      <Grid isContainer>
        <InputText
          required
          inputMask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          label="CNPJ"
          name="cnpj"
        />
        <InputText
          required
          label="Razão Social"
          name="company_name"
          xs={6}
        />
        <InputText
          label="Nome Fantasia"
          name="trading_name"
          xs={6}
        />
        <InputText
          required
          label="E-mail"
          name="email"
          xs={6}
        />
        <InputText
          required
          inputMask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/,  '-', /\d/, /\d/, /\d/, /\d/]}
          label="Telefone"
          name="phone"
          xs={6}
        />
      </Grid>

      {!isEditPage && (
        <Scope path="user">
          <h4 style={{ marginTop: 10 }}>Dados do usuário admin</h4>
          <Grid isContainer>
            <InputText
              required
              label="Nome"
              name="name"
            />
            <InputText
              required
              label="Usuário"
              name="username"
              xs={6}
            />
            <InputText
              required
              label="E-mail"
              name="email"
              xs={6}
            />
            <InputText
              required
              type="password"
              label="Senha"
              name="password"
              xs={6}
            />
            <InputText
              required
              type="password"
              label="Senha"
              name="password_confirmation"
              xs={6}
            />
          </Grid>
        </Scope>
      )}
    </Form>
  );
}

export default CustomForm;
