import React, { useState, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useRequest } from '../../hooks/RequestContext';
import { biProvidersRouteApi } from '../../routes/config/api';
import { listBIProvidersRoute, showBIProviderRoute } from '../../routes/config';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';

import LayoutCreate from '../../components/Layouts/Create';

import Form from './Form';

export const Create: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { post: requestPost } = useRequest();
  const history = useHistory();

  const createBIProvider = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const response = await requestPost(biProvidersRouteApi.path, {
          name: data.name,
          powerbi: {
            account_id: data.account_id,
            auth_client_id: data.auth_client_id,
            auth_client_secret: data.auth_client_secret,
          }
        });

        toast.success("Provedor de dados criado com sucesso!");
        history.push(showBIProviderRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível cadastrar este provedor de dados!");
      } finally {
        setLoading(false);
      }
    },
    [requestPost, history]
  );

  const handleSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;
      const schema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório'),
        account_id: Yup.string().required('Campo obrigatório'),
        auth_client_id: Yup.string().required('Campo obrigatório'),
        auth_client_secret: Yup.string().required('Campo obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      createBIProvider(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [createBIProvider]);

  return (
    <LayoutCreate
      breadcrumbs={[
        { text: 'Provedores de Dados', path: listBIProvidersRoute.path },
        { text: 'Criar Provedor de Dados' }
      ]}
      cardProps={{
        title: 'Criar novo provedor de dados',
        noData: false,
        loading: false,
      }}
      footerActionsProps={{
        onCancelRoute: listBIProvidersRoute.path,
        onSubmit: handleSubmit,
        loading,
      }}
    >
      <Form formRef={formRef} isCreatePage />
    </LayoutCreate>
  );
}
