import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

import { useRequest } from '../../hooks/RequestContext';

import Button from '../Button';

import { Container } from './styles';

export interface INavMenuProps {
  visible?: boolean;
  newRoute?: string;
  editRoute?: string;
  deleteRoute?: {
    route: string;
    redirect: string;
  };
  StartComponent?: React.FC;
}

const NavMenu: React.FC<INavMenuProps> = ({
  visible = true,
  newRoute,
  editRoute,
  deleteRoute,
  StartComponent,
}) => {
  const { del: requestDel } = useRequest();
  const history = useHistory();

  const handleDelete = useCallback(() => {
    const DELETE_CANCELED_MSG = 'DELETE_CANCELED';

    swal(
      'Atenção',
      'Deseja realmente excluir este registro?',
      {
        icon: "warning",
        buttons: [
          "Não",
          {
            text: "Sim",
            icon: "error",
            closeModal: false,
            className: 'sweetalert-btn-confirm-custom'
          },
        ] as any,
      }
    )
    .then((shouldDelete) => {
      if (!shouldDelete) throw new Error(DELETE_CANCELED_MSG);

      return requestDel(deleteRoute?.route || '')
    })
    .then(() => {
      swal("Oba!", "Item deletado com sucesso.", "success");

      history.push(deleteRoute?.redirect || "");
    })
    .catch((err) => {
      if (err.message !== DELETE_CANCELED_MSG) {
        swal(
          "Ops, algo deu errado!",
          "Não foi possível remover o registro. Tente novamente mais tarde.",
          "error"
        );
      }
    });
  }, [requestDel, deleteRoute?.route, history, deleteRoute?.redirect]);

  if (!visible) return <></>;

  return (
    <Container>
      {StartComponent && <StartComponent />}

      {!!deleteRoute?.route && (
        <Button
          className="delete-button"
          text="Deletar"
          option="danger"
          disabled={false}
          onClick={handleDelete}
        />
      )}

      {!!editRoute && (
        <Link to={editRoute}>
          <Button
            className="edit-button"
            text="Editar"
            option="info"
            disabled={false}
          />
        </Link>
      )}

      {!!newRoute && (
        <Link to={newRoute}>
          <Button
            className="new-button"
            text="Novo"
            option="success"
            disabled={false}
          />
        </Link>
      )}
    </Container>
  );
}

export default NavMenu;
