import React from 'react';

import { Container, TContainerOption } from './styles';

interface IButton {
  text: string;
  disabled: boolean;
  color?: string;
  option: TContainerOption;
  className?: string | undefined;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<IButton> = ({
  text,
  disabled,
  color,
  option,
  className,
  onClick
}) => {
  return (
    <Container
      className={className}
      color={color}
      option={option}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Container>
  );
}

export default Button;
