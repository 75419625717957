import React, { useState, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useRequest } from '../../hooks/RequestContext';
import { companiesRouteApi } from '../../routes/config/api';
import { listCompaniesRoute, showCompanyRoute } from '../../routes/config';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';

import LayoutCreate from '../../components/Layouts/Create';

import Form from './Form';

export const Create: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { post: requestPost } = useRequest();
  const history = useHistory();

  const createCompany = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        delete data.user.password_confirmation;

        const response = await requestPost(companiesRouteApi.path, data);

        toast.success("Empresa criada com sucesso!");
        history.push(showCompanyRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível cadastrar esta empresa!");
      } finally {
        setLoading(false);
      }
    },
    [requestPost, history]
  );

  const handleSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;
      const schema = Yup.object().shape({
        cnpj: Yup.string().required('Campo obrigatório'),
        company_name: Yup.string().required('Campo obrigatório'),
        email: Yup.string().required('Campo obrigatório'),
        phone: Yup.string().required('Campo obrigatório'),
        user: Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          username: Yup.string()
            .required("Campo obrigatório")
            .min(5, "No mínimo 5 caracteres"),
          email: Yup.string().email('E-mail inválido').required("Campo obrigatório"),
          password: Yup.string()
            .required("Senha deve ter no mínimo 8 digítos")
            .min(8, "No mínimo 8 digitos"),
          password_confirmation: Yup.string()
            .required("Senha deve ter no mínimo 8 digítos")
            .min(8, "No mínimo 8 digitos")
            .oneOf([Yup.ref("password"), ""], "Senha de confirmação incorreta"),
        }).required(),
      });

      await schema.validate(data, { abortEarly: false });

      createCompany(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [createCompany]);

  return (
    <LayoutCreate
      breadcrumbs={[
        { text: 'Empresas', path: listCompaniesRoute.path },
        { text: 'Criar Empresa' }
      ]}
      cardProps={{
        title: 'Criar nova empresa',
        noData: false,
        loading: false,
      }}
      footerActionsProps={{
        onCancelRoute: listCompaniesRoute.path,
        onSubmit: handleSubmit,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutCreate>
  );
}
