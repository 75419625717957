import React, { useState, InputHTMLAttributes } from "react";
import MaskedInput from "react-text-mask";

import Grid, { IGridProps } from '../../Grid';

import { Container } from "./styles";

export type Mask = Array<string | RegExp>;

export interface InputBaseProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Omit<IGridProps, 'isContainer' | 'isItem' | 'className'> {
  name: string;
  label?: string;
  inputRef?: any;
  inputMask?: Mask | ((value: string) => Mask);
  maskValue?: any;
  defaultValue?: any;
  error?: string | undefined;
  gridClassName?: string | undefined;
  startAdornment?: React.FunctionComponent;
  endAdornment?: React.ReactElement<any>;
  onChangeMask?: (e: any) => any;
}

const anyCharacterRegEx = /./;
const defaultMask = Array(100).fill(anyCharacterRegEx);

const InputBase: React.FC<InputBaseProps> = ({
  inputRef,
  label,
  inputMask,
  maskValue,
  error,
  onChangeMask,
  required,
  startAdornment: StartAdornment = null,
  endAdornment = null,
  xs,
  sm,
  md,
  spacing = 2,
  gridClassName,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <MaskedInput
      ref={inputRef}
      mask={inputMask || defaultMask}
      guide={false}
      value={maskValue}
      onChange={onChangeMask}
      render={(ref, inputMaskProps: any): any => {
        return (
          <Grid
            isItem
            className={gridClassName}
            spacing={spacing}
            xs={xs}
            md={md}
            sm={sm}
          >
            <Container
              error={!!error ? 'true' : 'false'}
              hasStartAdorment={!!StartAdornment ? 'true' : 'false'}
              isFocused={isFocused ? 'true' : 'false'}
            >
              {label && (
                <label className="label">
                  {`${label} ${required ? '*' : ''}`}
                </label>
              )}

              <div className="input-container">
                {!!StartAdornment && (
                  <div className="start-adorment">
                    <StartAdornment />
                  </div>
                )}

                <input
                  ref={ref as any}
                  {...inputMaskProps}
                  {...rest}
                  onFocus={(event) => {
                    setIsFocused(true)
                    inputMaskProps?.onFocus?.(event);
                  }}
                  onBlur={(event: any) => {
                    setIsFocused(false)
                    inputMaskProps?.onBlur?.(event);
                  }}
                />
              </div>

              {!!error && <label className="error">{error}</label>}
            </Container>
          </Grid>
        );
      }}
    />
  );
};

export default InputBase;
