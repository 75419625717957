import { createGlobalStyle } from 'styled-components';
import { transparentize, shade } from 'polished';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    -webkit-tap-highlight-color: ${
      props => transparentize(0.84, props.theme.colors.primary)
    };
  }

  html {
    font-size: calc(10px + 1vmin);
  }

  body {
    -webkit-font-smoothing: antialiased;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${props => props.theme.colors.backgrond};
    color: ${props => props.theme.colors.text.title};
  }

  h1, h2, h3 {
    font-weight: 800;
  }

  h4, h5, h6, strong {
    font-weight: 600;
  }

  h1, h2, h3, h4, h5, h6, strong {
    color: ${props => props.theme.colors.text.title};
  }

  p {
    color: ${props => props.theme.colors.text.p};
  }

  button {
    cursor: pointer;
    color: ${props => props.theme.colors.text.light};
  }

  ::selection {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
  }

  /* for firefox */
  ::moz-selection {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
  }

  & [class^='Toastify__toast'],
    [class*=' Toastify__toast'] {
      border-radius: 2px;
    }

  .Toastify__toast--default {
    background: ${props => props.theme.colors.text.title};
    color: ${props => props.theme.colors.white};
  }

  .Toastify__toast--info {
    background: ${props => props.theme.colors.blue};
  }

  .Toastify__toast--success {
    background: ${props => props.theme.colors.green};
  }

  .Toastify__toast--warning {
    background: ${props => props.theme.colors.primary};
  }

  .Toastify__toast--error {
    background: ${props => props.theme.colors.red};
  }

  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  .container {
    display: flex;
    width: 1170px;
    padding: 0 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .swal-title {
    color: ${props => props.theme.colors.text.title};
  }

  .swal-text {
    color: ${props => props.theme.colors.text.cream};
    text-align: center;
  }

  .swal-icon--success__line {
    background-color: ${props => props.theme.colors.green};
  }

  .swal-icon--success__ring {
    border: 4px solid ${props => transparentize(0.75, props.theme.colors.green)};
  }

  .swal-icon--error {
    border-color: ${props => props.theme.colors.peach};
  }

  .swal-icon--error__line {
    background-color: ${props => shade(0.1, props.theme.colors.peach)};
  }

  .swal-icon--warning {
    border-color: ${props => props.theme.colors.primary};
  }

  .swal-icon--warning__body, .swal-icon--warning__dot {
    background-color: ${props => props.theme.colors.primary};
  }

  .swal-button {
    background-color: ${props => props.theme.colors.blue};
    transition: background-color 150ms ease-in-out;

    &:hover, &:active {
      background-color: ${props => transparentize(0.25, props.theme.colors.blue)} !important;
    }
  }

  .swal-button--cancel {
    background-color: #eeeeee;

    &:hover {
      background-color: #dddddd !important;
    }
  }

  .sweetalert-btn-confirm-custom {
    background-color: ${props => props.theme.colors.green};
    transition: background-color 150ms ease-in-out;

    &:hover, &:active {
      background-color: ${props => transparentize(0.25, props.theme.colors.green)} !important;
    }
  }

  @media(max-width: 1200px) {
    .container {
      width: 1170px;
    }
  }

  @media(max-width: 1000px) {
    .container {
      width: 970px;
    }
  }

  @media(max-width: 800px) {
    html {
      font-size: 16px;
    }

    & [class^='Toastify__toast'],
      [class*=' Toastify__toast'] {
        border-radius: 0;
      }

    .container {
      width: 100vw;
    }
  }
`;
