import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    img {
      width: 150px;
    }

    p {
      display: flex;
      justify-content: center;
    }
  }

  form {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .content {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 440px;
      height: 450px;
      border-radius: 5px;
      box-shadow: 0 1px 20px 0 ${props => props.theme.colors.black_150};
      overflow: hidden;
      animation: ${appearFromLeft} 1s;

      img {
        height: 210px;
        width: 115%;
      }

      h1 {
        position: absolute;
        top: 0;
        left: -4px;
        color: ${props => props.theme.colors.white};
        font-size: 3.5rem;
      }

      .input-group {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        margin-top: -20px;

        .password-grid {
          padding-top: 0px;
        }

        button {
          width: calc(100% - 16px);
          margin-top: 10px;
        }
      }
    }
  }

  footer {
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;
    padding: 24px 24px 30px 24px;
    text-align: center;

    p {
      font-size: 0.9rem;
    }
  }

  @media(max-width: 800px) {
    form {
      .content {
        animation: none;
      }
    }
  }

  @media(max-width: 500px) {
    header {
      padding: 0;
    }

    form {
      .content {
        width: 100%;
      }
    }
  }
`;
