import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ICompany } from './types';
import { listCompaniesRoute, editCompanyRoute } from '../../routes/config';
import { companiesRouteApi } from '../../routes/config/api';
import { useRequest } from '../../hooks/RequestContext';
import isBlank from '../../utils/isBlank';
import { formatCNPJ } from '../../utils/formatCNPJ';
import { formatPhone } from '../../utils/formatPhone';

import LayoutShow from '../../components/Layouts/Show';
import InputRead from '../../components/Input/Read';

interface IShowParams {
  id: string;
}

export const Show: React.FC = () => {
  const [company, setCompany] = useState<ICompany>({} as ICompany);
  const [loading, setLoading] = useState(true);
  const { id: companyId } = useParams<IShowParams>();
  const { get: requestGet } = useRequest();

  useEffect(() => {
    async function findCompany() {
      try {
        const response = await requestGet(`${companiesRouteApi.path}/${companyId}`);
        if (!isBlank(response)) {
          setCompany({
            ...response,
            cnpj: formatCNPJ(response.cnpj),
            phone: formatPhone(response.phone),
          });
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findCompany();
  }, [requestGet, companyId]);

  return (
    <LayoutShow
      breadcrumbs={[
        { text: 'Empresas', path: listCompaniesRoute.path },
        { text: 'Visualizar Empresa' }
      ]}
      navMenuProps={{
        deleteRoute: {
          route: `${companiesRouteApi.path}/${companyId}`,
          redirect: listCompaniesRoute.path,
        },
        editRoute: editCompanyRoute.build({ id: companyId }),
      }}
      cardProps={{
        title: 'Visão geral da empresa',
        noData: isBlank(company),
        loading,
      }}
    >
      <InputRead label="CNPJ" value={company.cnpj} />
      <InputRead label="Razão Social" value={company.company_name} xs={6} />
      <InputRead label="Nome Fantasia" value={company.trading_name} xs={6} />
      <InputRead label="E-mail" value={company.email} xs={6} />
      <InputRead label="Telefone" value={company.phone} xs={6} />
    </LayoutShow>
  );
}
