import React from "react";
import { Switch } from "react-router-dom";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import { Create, Edit, List, Show } from "../../pages/Modules";

export const createModuleRoute = new Route("/modulos/novo");

export const editModuleRoute = new Route("/modulos/:id/editar");

export const listModulesRoute = new Route("/modulos");

export const showModuleRoute = new Route("/modulos/:id/visualizar");

export const ModulesRouteComponent: React.FC = () => (
  <Switch>
    <RouteWrapper
      exact
      isPrivate
      path={createModuleRoute.path}
      component={Create}
    />
    <RouteWrapper
      exact
      isPrivate
      path={editModuleRoute.path}
      component={Edit}
    />
    <RouteWrapper
      exact
      isPrivate
      path={listModulesRoute.path}
      component={List}
    />
    <RouteWrapper
      exact
      isPrivate
      path={showModuleRoute.path}
      component={Show}
    />

    <RouteWrapper
      isPrivate
      redirectIfNotExact
      path={listModulesRoute.path}
      component={List}
    />
  </Switch>
);
