import React from 'react';

import { modulesRouteApi } from '../../routes/config/api';
import {
  showModuleRoute,
  editModuleRoute,
  createModuleRoute
} from '../../routes/config';

import LayoutList from '../../components/Layouts/List';
import Table from '../../components/Table';

export const List: React.FC = () => {
  return (
    <LayoutList
      breadcrumbs={[{ text: 'Módulos' }]}
      navMenuProps={{
        visible: true,
        newRoute: createModuleRoute.path
      }}
      tableProps={{
        title: 'Meus Módulos',
        actions: {
          enable: true,
          showRoute: showModuleRoute,
          editRoute: editModuleRoute,
          deleteRoute: modulesRouteApi.path,
        },
        columns: [
          { Header: 'Nome', accessor: 'name', minWidth: 300 },
          {
            Header: 'Provedor',
            accessor: 'bi_provider',
            minWidth: 300,
            Cell: ({ row }) => {
              const originalData = row.original as any;
              return originalData?.bi_provider?.name || '';
            },
          },
        ],
        fetch: {
          url: modulesRouteApi.path,
        }
      }}
    >
      <div style={{ marginTop: 16 }}>
        <Table
          title="Módulos compartilhados com minha empresa"
          columns={[
            {
              Header: 'Nome',
              accessor: 'name',
              minWidth: 300
            },
            { Header: 'Proprietário', accessor: 'owner_name', minWidth: 300 },
          ]}
          fetch={{ url: `${modulesRouteApi.path}/shared-with-me` }}
          actions={{ enable: false }}
        />
      </div>
    </LayoutList>
  );
}
