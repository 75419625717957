import styled from "styled-components";

const height = 55;

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: ${height}px;
  position: fixed;
  z-index: 1500;
  bottom: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.white};
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  -webkit-box-shadow: 2px 4px 9px 1px ${props => props.theme.colors.black_100};
  -moz-box-shadow: 2px 4px 9px 1px ${props => props.theme.colors.black_100};
  box-shadow: 2px 4px 9px 1px ${props => props.theme.colors.black_100};

  > *:not(:first-child) {
    margin-left: 8px;
  }

  @media(max-width: 600px) {
    padding: 0 16px;

    button {
      width: 100%;
    }
  }
`;

export const EmptyContainer = styled.div`
  width: 100%;
  height: ${height}px;
`;
