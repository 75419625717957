import React, { useState, useCallback, useEffect } from 'react';
import Modal, { Styles } from 'react-modal';
import ReactLoading from 'react-loading';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';

import Grid from '../../../components/Grid';

import { getReportsFromBIProviderRouteApi } from '../../../routes/config/api';
import { useRequest } from '../../../hooks/RequestContext';

import { Container, Content } from './styles';

interface IReport {
  id: string;
  name: string;
  description: string;
  dataset_id: string;
}

export interface IGroupReport {
  id: string;
  name: string;
  reports: IReport[];
}

interface ISelectedReport {
  id: string;
  name: string;
  group_id: string;
  dataset_id: string;
}

interface ISelectBIProviderModuleModalProps {
  biProviderId: number | null;
  onSelect(report: ISelectedReport): void;
}

const customStyles: Styles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: '1px solid #dedede',
  },
  overlay: {
    zIndex: 9000,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  }
};

const SelectBIProviderModuleModal: React.FC<ISelectBIProviderModuleModalProps> = ({
  biProviderId,
  onSelect
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IGroupReport[]>([]);
  const { get: requestGet } = useRequest();

  const handleFetchReports = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await requestGet(getReportsFromBIProviderRouteApi.build({ id: biProviderId }))
      setData(response as any);
    } finally {
      setIsLoading(false);
    }
  }, [requestGet, biProviderId]);

  useEffect(() => {
    if (isOpen && biProviderId) {
      handleFetchReports();
    }
  }, [isOpen, biProviderId, handleFetchReports]);

  return (
    <Container>
      <button disabled={!biProviderId} onClick={() => setIsOpen(true)}>
        Buscar dados do provedor
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        onAfterClose={() => setData([])}
        style={customStyles}
        contentLabel="Módulos"
      >
        <Content>
          <Grid isContainer spacing={6} className="header">
            <h4>Selecione um relatório</h4>
            <button onClick={() => setIsOpen(false)}>
              <AiOutlineClose size={20} />
            </button>
          </Grid>
          <Grid isContainer spacing={4}>
            {isLoading && (
              <div className="loading">
                <ReactLoading type="bars" className="spinner" width={38} />
              </div>
            )}

            {(!isLoading && !data.length) && (
              <div className="no-data">
                <span>Nenhuma informação encontrada.</span>
              </div>
            )}

            {!isLoading && (
              <Grid isItem spacing={3}>
                {data.map((item) => (
                  <div key={item.id} className="item">
                    <strong>Grupo {item.name}</strong>
                    <ul>
                      {item.reports.map((report) => (
                        <li
                          key={report.id}
                          onClick={() => {
                            onSelect({
                              id: report.id,
                              name: report.name,
                              group_id: item.id,
                              dataset_id: report.dataset_id
                            });
                            setIsOpen(false);
                          }}
                        >
                          <HiOutlineDocumentReport size={20} />
                          <span>{report.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Grid>
            )}
          </Grid>
        </Content>
      </Modal>
    </Container>
  );
}

export default SelectBIProviderModuleModal;
