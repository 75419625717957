import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IModule } from './types';
import { listModulesRoute, editModuleRoute } from '../../routes/config';
import { modulesRouteApi } from '../../routes/config/api';
import { useRequest } from '../../hooks/RequestContext';
import { useAuth } from '../../hooks/AuthContext';
import isBlank from '../../utils/isBlank';
import getUserType from '../../utils/getUserType';
import { formatModulePermission } from '../../utils/module-permissions';
import { formatCNPJ } from '../../utils/formatCNPJ';
import { isRootUser } from '../../utils/userTypes';

import LayoutShow from '../../components/Layouts/Show';
import Grid from '../../components/Grid';
import InputRead from '../../components/Input/Read';
import CheckboxRead from '../../components/Input/CheckboxRead';
import Table from '../../components/Table';
import ImagePreview from './ImagePreview';

import { Container } from './styles';

interface IShowParams {
  id: string;
}

interface IListShareModules {
  moduleId: string;
}

const ListShareModules: React.FC<IListShareModules> = ({ moduleId }) => (
  <div className="table-container">
    <Table
      title="Compartilhado com as seguintes empresas"
      columns={[
        {
          Header: 'CNPJ',
          accessor: 'cnpj',
          minWidth: 200,
          Cell({ row }) {
            const originalData = row.original as any;
            return <span>{formatCNPJ(originalData.company.cnpj)}</span>;
          }
        },
        {
          Header: 'Nome',
          accessor: 'name',
          minWidth: 300,
          Cell({ row }) {
            const originalData = row.original as any;
            return <span>{originalData.company.company_name}</span>;
          }
        },
      ]}
      fetch={{ url: `${modulesRouteApi.path}/shared-with-companies/${moduleId}` }}
      actions={{ enable: false }}
    />
  </div>
);

export const Show: React.FC = () => {
  const [module, setModule] = useState<IModule>({} as IModule);
  const [loading, setLoading] = useState(true);
  const { id: moduleId } = useParams<IShowParams>();
  const { get: requestGet } = useRequest();
  const { user } = useAuth();

  useEffect(() => {
    async function findModule() {
      try {
        const response = await requestGet(`${modulesRouteApi.path}/${moduleId}`);
        if (!isBlank(response)) {
          setModule({
            ...response,
            type: getUserType(response.type)
          });
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findModule();
  }, [requestGet, moduleId]);

  return (
    <Container>
      <LayoutShow
        breadcrumbs={[
          { text: 'Módulos', path: listModulesRoute.path },
          { text: 'Visualizar Módulo' }
        ]}
        navMenuProps={{
          deleteRoute: {
            route: `${modulesRouteApi.path}/${moduleId}`,
            redirect: listModulesRoute.path,
          },
          editRoute: editModuleRoute.build({ id: moduleId }),
        }}
        cardProps={{
          title: 'Visão geral do módulo',
          noData: isBlank(module),
          loading,
        }}
      >
        <InputRead label="Nome" value={module.name} xs={6} />
        <InputRead
          label="Provedor de Dados"
          value={module.bi_provider?.name}
          xs={6}
        />
        <InputRead label="Group Id" value={module.powerbi_module?.group_id} xs={6} />
        <InputRead label="Report Id" value={module.powerbi_module?.report_id} xs={6} />
        <InputRead label="Dataset Id" value={module.powerbi_module?.dataset_id} xs={6} />
        <InputRead
          label="Permissão"
          value={formatModulePermission(module.powerbi_module?.permissions)}
          xs={6}
        />

        <Grid isItem xs={6} spacing={0}>
          <CheckboxRead
            label="Mostrar painel de filtros"
            value={module.powerbi_module?.filter_pane_enabled}
          />
          <CheckboxRead
            label="Mostrar painel de navegação"
            value={module.powerbi_module?.nav_content_pane_enabled}
          />
          <CheckboxRead
            label="Usar identidade"
            value={module.powerbi_module?.identities}
          />
        </Grid>
        <ImagePreview imageUrl={module.image_url} />
      </LayoutShow>

      {isRootUser(user.type) && <ListShareModules moduleId={moduleId} />}
    </Container>
  );
}
