import React from "react";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import UserProfile from "../../pages/UserProfile";

export const userProfileRoute = new Route("/minha-conta");

export const UserProfileRouteComponent: React.FC = () => (
  <RouteWrapper
    exact
    isPrivate
    path={userProfileRoute.path}
    component={UserProfile}
  />
);
