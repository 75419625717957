import React, { useState, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import jsonFormData from 'json-form-data';

import { useRequest } from '../../hooks/RequestContext';
import { modulesRouteApi } from '../../routes/config/api';
import { listModulesRoute, showModuleRoute } from '../../routes/config';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';

import LayoutCreate from '../../components/Layouts/Create';

import Form from './Form';

export const Create: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { post: requestPost } = useRequest();
  const history = useHistory();

  const createModule = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const formData = jsonFormData({
          bi_provider_id: data.bi_provider,
          name: data.name,
          file: data.image || undefined,
          powerbi_module: data.powerbi_module,
          shared_with_companies: data.shared_modules,
        });
        const response = await requestPost(modulesRouteApi.path, formData);

        toast.success("Módulo criado com sucesso!");
        history.push(showModuleRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível cadastrar este módulo!");
      } finally {
        setLoading(false);
      }
    },
    [requestPost, history]
  );

  const handleSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;
      const datasetSchema = !!data.powerbi_module?.identities
        ? Yup.string()
        : Yup.string().required('Campo obrigatório');
      const schema = Yup.object().shape({
        bi_provider: Yup.mixed().required("Campo obrigatório"),
        name: Yup.string().required("Campo obrigatório"),
        powerbi_module: Yup.object().shape({
          group_id: Yup.string().required('Campo obrigatório'),
          report_id: Yup.string().required('Campo obrigatório'),
          dataset_id: datasetSchema,
        }),
      });

      await schema.validate(data, { abortEarly: false });

      createModule(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [createModule]);

  return (
    <LayoutCreate
      breadcrumbs={[
        { text: 'Módulos', path: listModulesRoute.path },
        { text: 'Criar Módulo' }
      ]}
      cardProps={{
        title: 'Criar novo módulo',
        noData: false,
        loading: false,
      }}
      footerActionsProps={{
        onCancelRoute: listModulesRoute.path,
        onSubmit: handleSubmit,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutCreate>
  );
}
