import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  button {
    background-color: transparent;
    border: none;
    color: ${props => props.theme.colors.text.p};
    font-size: 0.95rem;
    pointer-events: none;
    cursor: default;
    margin-top: 34px;

    &:not(:disabled) {
      pointer-events: auto;
      cursor: pointer;
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export const Content = styled.div`
  width: 800px;
  height: 80vh;

  .header {
    display: flex;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.white};
    font-size: 1.1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    button {
      background-color: transparent;
      color: ${props => props.theme.colors.text.cream};
      border: none;
    }
  }

  .loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 8px 0;

    .spinner {
      height: 40px !important;
      fill: ${props => props.theme.colors.primary} !important;
    }
  }

  .no-data {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    text-align: center;

    span {
      font-size: 0.9rem;
    }
  }

  .item {
    margin-top: -16px;

    strong {
      line-height: 40px;
    }

    ul {
      li {
        display: flex;
        padding: 0 0 12px 10px;
        list-style: none;
        cursor: pointer;
        transition: color 100ms ease-in-out;

        svg {
          margin-right: 4px;
        }

        &:hover {
          color: ${props => props.theme.colors.primary};
        }
      }
    }
  }

  @media(max-width: 850px) {
    width: 95vw;
  }
`;
