import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 16px;

  div, .link {
    display: flex;
  }

  @media(max-width: 600px) {
    display: none;
  }
`;

export const Link = styled(RouterLink)`
  color: ${(props) => props.theme.colors.text.p};
  font-size: 0.85rem;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Typography = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 0.85rem;
  font-weight: 600;
`;

export const Separator = styled.div`
  display: flex;
  margin-left: 8px;
  user-select: none;
  margin-right: 8px;
  color: ${(props) => props.theme.colors.text.p};
`;
