import React, { useMemo, useRef, useEffect } from 'react';
import { OptionTypeBase } from 'react-select';
import ReactSelect, { Props as SelectProps } from 'react-select';
import { useField } from '@unform/core';
import { shade, transparentize } from "polished";

import { getCurrentTheme } from "../../../styles/theme";

import Grid, { IGridProps } from "../../Grid";

import { Container } from './styles';

type TSelectProps = Omit<SelectProps<OptionTypeBase, false | true>, "loadOptions">;

interface Props
  extends TSelectProps,
    Omit<IGridProps, 'isContainer' | 'isItem' | 'className'> {
  name: string;
  label?: string;
  required?: boolean;
  gridClassName?: string | undefined;
}

const Select: React.FC<Props> = ({
  name,
  label,
  placeholder,
  required,
  xs = 12,
  sm = 12,
  md = 12,
  spacing = 2,
  gridClassName,
  ...rest
}) => {
  const selectRef = useRef<any>(null);
  const currentTheme = useMemo(() => getCurrentTheme(), []);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      ref: selectRef.current,
      name: fieldName,
      setValue(ref: any, value) {
        if (value !== undefined) {
          ref.select.setValue(value);
        }
      },
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.select.state.selectValue) {
            return [];
          }

          return ref.select.state.selectValue.map(
            (option: OptionTypeBase) => option.value,
          );
        }

        if (!ref.select.state.selectValue) {
          return null;
        }

        const [option] = ref.select.state.selectValue;

        return option.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Grid
      isItem
      xs={xs}
      sm={sm}
      md={md}
      spacing={spacing}
      className={gridClassName}
    >
      <Container>
        {label && (
          <label className="label">
            {`${label} ${required ? '*' : ''}`}
          </label>
        )}

        <ReactSelect
          isClearable
          isSearchable
          loadingMessage={() => "Carregando..."}
          noOptionsMessage={({ inputValue }) => {
            return !!inputValue
              ? "Ops, nenhuma informação encontrada"
              : "Digite alguma coisa para pesquisar";
          }}
          {...rest}
          ref={selectRef}
          defaultValue={defaultValue}
          classNamePrefix="react-select"
          placeholder={placeholder || "Selecione uma opção"}
          styles={{
            input: (style) => ({
              ...style,
              color: currentTheme.colors.text.title,
              fontSize: "0.9rem",
              fontWeight: 500,
            }),
            singleValue: (singleValueStyle) => ({
              ...singleValueStyle,
              color: currentTheme.colors.text.title,
              fontSize: "0.9rem",
              fontWeight: 500,
            }),
            placeholder: (placeholderStyle) => ({
              ...placeholderStyle,
              color: currentTheme.colors.text.p,
              fontSize: "0.8rem",
              fontWeight: 500,
            }),
            menu: (menuStyle) => ({
              ...menuStyle,
              top: 43,
              zIndex: 1600,
            }),
            menuList: (menuListStyle) => ({
              ...menuListStyle,
              fontSize: "0.8rem",
            }),
            control: () => ({
              display: "flex",
              flexDirection: "row",
              border: `1px solid ${error ? currentTheme.colors.red : "#cad1d7"}`,
              color: currentTheme.colors.text.title,
              width: "100%",
              height: 45,
              borderRadius: 5,
              "&:focus-within": {
                border: `1px solid ${error ? currentTheme.colors.red : currentTheme.colors.primary}`,
              },
              "&:hover": {
                border: `1px solid ${error ? currentTheme.colors.red : currentTheme.colors.primary}`,
              },
            }),
            multiValue: (styles) => ({
              ...styles,
              backgroundColor: transparentize(0.84, currentTheme.colors.primary),
            }),
            multiValueLabel: (styles) => ({
              ...styles,
              padding: "3px 8px",
              color: currentTheme.colors.primary,
              fontWeight: 500,
              fontSize: "0.7rem",
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              color: currentTheme.colors.white,
              backgroundColor: currentTheme.colors.primary,
              cursor: "pointer",
              transition: "background-color 150ms ease-in-out",
              ":hover": {
                backgroundColor: shade(0.2, currentTheme.colors.primary),
              },
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#dedede",
              primary50: "#dedede",
              primary: currentTheme.colors.primary,
              neutral0: currentTheme.colors.white,
              neutral50: "#dedede",
              neutral60: error ? currentTheme.colors.red : currentTheme.colors.primary,
              neutral80: "#656565",
            },
          })}
        />

        {!!error && <label className="error">{error}</label>}
      </Container>
    </Grid>
  );
};
export default Select;
