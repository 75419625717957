import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import Button from '../Button';

import { Container, EmptyContainer } from "./styles";

export interface IFooterActionsProps {
  loading?: boolean;
  onCancelRoute?: string;
  onCancel?: () => void;
  onSubmit(): void;
}

export const FooterActionsEmpty = EmptyContainer;

const FooterActions: React.FC<IFooterActionsProps> = ({
  loading,
  onCancelRoute,
  onCancel,
  onSubmit,
}) => {
  const history = useHistory();

  const handleCancel = useCallback(() => {
    if (onCancelRoute) {
      history.replace(onCancelRoute, {});
    }
  }, [onCancelRoute, history]);

  return (
    <Container>
      <Button
        text="Cancelar"
        disabled={false}
        option="danger"
        onClick={onCancel ?? handleCancel}
      />
      <Button
        text="Salvar"
        disabled={!!loading}
        option="success"
        onClick={onSubmit}
      />
    </Container>
  );
};

export default FooterActions;
