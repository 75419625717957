import React from 'react';

import { companiesRouteApi } from '../../routes/config/api';
import {
  showCompanyRoute,
  editCompanyRoute,
  createCompanyRoute,
} from '../../routes/config';
import { formatCNPJ } from '../../utils/formatCNPJ';
import { formatPhone } from '../../utils/formatPhone';

import LayoutList from '../../components/Layouts/List';

export const List: React.FC = () => {
  return (
    <LayoutList
      breadcrumbs={[{ text: 'Empresas' }]}
      navMenuProps={{
        visible: true,
        newRoute: createCompanyRoute.path,
      }}
      tableProps={{
        title: 'Listagem de Empresas',
        actions: {
          enable: true,
          showRoute: showCompanyRoute,
          editRoute: editCompanyRoute,
          deleteRoute: companiesRouteApi.path,
        },
        columns: [
          {
            Header: 'CNPJ',
            accessor: 'cnpj',
            minWidth: 200,
            Cell({ row }) {
              return <span>{formatCNPJ(row.values.cnpj)}</span>;
            }
          },
          { Header: 'Razão Social', accessor: 'company_name', width: 300 },
          { Header: 'Nome Fantasia', accessor: 'trading_name', width: 300 },
          { Header: 'E-mail', accessor: 'email', width: 250 },
          {
            Header: 'Telefone',
            accessor: 'phone',
            width: 170,
            Cell({ row }) {
              return <span>{formatPhone(row.values.phone)}</span>;
            }
          },
        ],
        fetch: {
          url: companiesRouteApi.path,
        }
      }}
    />
  );
}
