import styled, { css } from 'styled-components';

interface IContainer {
  error: 'true' | 'false';
  hasStartAdorment: 'true' | 'false'
  isFocused: 'true' | 'false'
}

const cssError = css`
  border-color: ${props => props.theme.colors.red};
`;

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;

  .label {
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0 0 4px 5px;
  }

  .input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45px;
    border: 1px solid #cad1d7;
    border-radius: 5px;
    padding: 1px;
    background-color: ${props => props.theme.colors.white};
    transition: border-color 150ms ease-in-out;
    border-color: ${
      props => props.isFocused === 'true'
        ? props.theme.colors.primary
        : '#cad1d7'
    };

    ${props => props.isFocused === 'false' && `
      &:hover {
        border-color: ${props.error === 'true'
          ? props.theme.colors.red
          : props.theme.colors.primary
        };
      }
    `}

    .start-adorment {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0 12px;
      color: ${
        props => props.isFocused === 'true'
          ? props.theme.colors.primary
          : props.theme.colors.text.p
      };

      ${props => props.error === 'true' && `
        color: ${props.theme.colors.red};
      `}
    }

    input {
      border: none;
      height: 100%;
      width: 100%;
      font-size: 0.95rem;
      padding: ${props => props.hasStartAdorment === 'true' ? '0px' : '0 12px'};
      padding-right: 12px;
      color: ${props => props.theme.colors.text.title};

      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props.theme.colors.text.p};
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props.theme.colors.text.p};
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props.theme.colors.text.p};
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active  {
          -webkit-box-shadow: 0 0 0 30px ${props => props.theme.colors.white} inset !important;
      }
    }

    ${props => props.error === 'true' && cssError}
  }

  .error {
    font-size: 0.7rem;
    font-weight: 500;
    padding: 4px 0 0 5px;
    color: ${props => props.theme.colors.red};
  }
`;
