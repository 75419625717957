import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import { Menu, MenuDivider } from '@szhsin/react-menu';
import {
  AiOutlineLogin,
  AiOutlineUser,
  AiOutlineBank,
  AiOutlineSetting,
  AiOutlineDatabase
} from 'react-icons/ai';
import { BiBuilding } from 'react-icons/bi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { FiUsers } from 'react-icons/fi';
import { RiListSettingsLine } from 'react-icons/ri';

import { useAuth } from '../../../hooks/AuthContext';
import {
  dashboardRoute,
  listCompaniesRoute,
  listBIProvidersRoute,
  listModulesRoute,
  listUsersRoute,
  listProfilesRoute,
  userProfileRoute,
  myCompanyRoute
} from '../../../routes/config';
import { isRootUser, isOwnerUser } from '../../../utils/userTypes';
import logoImg from '../../../assets/images/logo.png';
import shortLogoImg from '../../../assets/images/short-logo.png';

import InputSearch from './InputSearch';

import { Container, MenuItem } from './styles';

interface IMenuAdmin {
  canManageCompanies: boolean;
  canManageBiProviders: boolean;
  canManageModules: boolean;
  canManageUsers: boolean;
  canManageProfiles: boolean;
}

const MenuAdmin: React.FC<IMenuAdmin> = ({
  canManageCompanies,
  canManageBiProviders,
  canManageModules,
  canManageUsers,
  canManageProfiles
}) => {
  const noPermissions = useMemo(
    () => {
      const noAdminPermissions =
        !canManageBiProviders &&
        !canManageModules &&
        !canManageUsers &&
        !canManageProfiles;

      if (!canManageCompanies) return noAdminPermissions;

      return !canManageCompanies && noAdminPermissions;
    },
    [
      canManageBiProviders,
      canManageCompanies,
      canManageModules,
      canManageProfiles,
      canManageUsers
    ]
  );

  if (noPermissions) return <></>;

  return (
    <Menu
      arrow={true}
      align="end"
      menuButton={
        <div className="menu-admin">
          <button>
            <AiOutlineSetting size={29} />
          </button>
        </div>
      }
      className="noSelect"
    >
      {canManageCompanies
        ? (
          <MenuItem className="noSelect">
            <Link to={listCompaniesRoute.path}>
              <div className="icon-link">
                <BiBuilding size={20} />
              </div>
              <span>Empresas</span>
            </Link>
          </MenuItem>
        ) : <div />
      }

      {canManageBiProviders
        ? (
          <MenuItem className="noSelect">
            <Link to={listBIProvidersRoute.path}>
              <div className="icon-link">
                <AiOutlineDatabase size={20} />
              </div>
              <span>Provedor de Dados</span>
            </Link>
          </MenuItem>
        ) : <div />
      }

      {canManageModules
        ? (
          <MenuItem className="noSelect">
            <Link to={listModulesRoute.path}>
              <div className="icon-link">
                <HiOutlineDocumentReport size={21} />
              </div>
              <span>Módulos</span>
            </Link>
          </MenuItem>
        ) : <div />
      }

      {canManageUsers
        ? (
          <MenuItem className="noSelect">
            <Link to={listUsersRoute.path}>
              <div className="icon-link">
                <FiUsers size={19} />
              </div>
              <span>Usuários</span>
            </Link>
          </MenuItem>
        ) : <div />
      }

      {canManageProfiles
        ? (
          <MenuItem className="noSelect">
            <Link to={listProfilesRoute.path}>
              <div className="icon-link">
                <RiListSettingsLine size={19} />
              </div>
              <span>Perfis</span>
            </Link>
          </MenuItem>
        ) : <div />
      }
    </Menu>
  );
}

const Header: React.FC = () => {
  const { user, signOut } = useAuth();
  const canManageCompanies = useMemo(() => {
    return isRootUser(user.type);
  }, [user.type]);

  const firstLetterName = useCallback((): string => {
    const [firstLetter]: any = user.name || '';

    return firstLetter?.toUpperCase() || '?';
  }, [user]);

  return (
    <Container>
      <Link className="logo-container" to={dashboardRoute.path}>
        <img className="logo" src={logoImg} alt="Datasim logo"/>
        <img className="short-logo" src={shortLogoImg} alt="Datasim logo"/>
      </Link>

      <div className="menu-options">
        <InputSearch />

        <MenuAdmin
          canManageCompanies={canManageCompanies}
          canManageBiProviders={user.profile.can_manage_bi_providers}
          canManageModules={user.profile.can_manage_modules}
          canManageUsers={user.profile.can_manage_users}
          canManageProfiles={user.profile.can_manage_profiles}
        />

        <Menu
          arrow={true}
          align="end"
          menuButton={
            <div className="menu-avatar">
              <span>{firstLetterName()}</span>
            </div>
          }
          className="noSelect"
        >
          <MenuItem className="noSelect">
            <Link to={userProfileRoute.path}>
              <div className="icon-link">
                <AiOutlineUser size={20} />
              </div>
              <span>Minha Conta</span>
            </Link>
          </MenuItem>

          {canManageCompanies || isOwnerUser(user.type)
            ? [
              <MenuItem key="menu-id-my-company" className="noSelect">
                <Link to={myCompanyRoute.path}>
                  <div className="icon-link">
                    <AiOutlineBank size={20} />
                  </div>
                  <span>Minha Empresa</span>
                </Link>
              </MenuItem>,
              <MenuDivider key="menu-id-divider" />
            ]
            : <MenuDivider />
          }

          <MenuItem className="noSelect" onClick={signOut}>
            <AiOutlineLogin size={19} />
            <span>Sair</span>
          </MenuItem>
        </Menu>
      </div>
    </Container>
  );
}

export default Header;
