import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .label {
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0 0 4px 5px;
  }

  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 45px;
    padding: 6px 12px;
    border: 1px solid #cad1d7;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.text.title};
    font-size: 0.95rem;
  }
`;
