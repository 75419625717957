import React from 'react';

import { useProfileModules } from '../../hooks/ProfileModulesContext';

import Grid from '../../components/Grid';
import Item from './Item';

import noData from '../../assets/images/no-data.svg';

import { NoData } from './styles';

const Dashboard: React.FC = () => {
  const { modules } = useProfileModules();

  return (
    <Grid isContainer spacing={2}>
      {!modules.length && (
        <NoData>
          <img src={noData} alt="Imagem nenhum relatório encontrado"/>
          <span>Ops, nenhum relatório encontrado :(</span>
        </NoData>
      )}

      {modules.map((module) => (
        <Item
          key={module.id}
          name={module.name}
          route={module.route}
          imageUrl={module.image_url || ''}
        />
      ))}
    </Grid>
  );
}

export default Dashboard;
