import React from 'react';
import { TableProps, HeaderGroup, Row, TableBodyProps } from 'react-table';

import { Container, Table, TableHead, TableBody, Tr, Th, Td } from './styles';

interface IResponsiveTableProps {
  tableProps: TableProps;
  headerGroups: HeaderGroup[];
  rows: Row[];
  tableBodyProps: TableBodyProps;
  prepareRow(row: Row): void;
  isLoading: boolean;
  enableActions: boolean;
}

const ResponsiveTable: React.FC<IResponsiveTableProps> = ({
  tableProps,
  headerGroups,
  rows,
  tableBodyProps,
  prepareRow,
  enableActions,
  isLoading,
}) => {

  const noData = !rows.length && !isLoading;

  return (
    <Container>
      <Table {...tableProps} className="align-items-center table">
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, key) => {
                const isLastPos = (headerGroup.headers.length - 1) === key;
                const isFixed = isLastPos && enableActions;

                return (
                  <Th
                    {...column.getHeaderProps()}
                    isFixed={isFixed}
                    className={isFixed ? "th-actions" : ""}
                  >
                    {column.render('Header')}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </TableHead>
        <TableBody {...tableBodyProps}>
          {noData && (
            <Tr>
              <Td>
                <span className="no-data">
                  Nenhuma informação encontrada
                </span>
              </Td>
            </Tr>
          )}

          {rows.map((row) => {
            prepareRow(row);

            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell, key) => {
                  const isLastPos = (row.cells.length - 1) === key;
                  const isFixed = isLastPos && enableActions;

                  return (
                    <Td {...cell.getCellProps()} isFixed={isFixed}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
}

export default ResponsiveTable;
