import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useField } from "@unform/core";
import { RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri';

import Grid, { IGridProps } from '../../Grid';

import { Container } from './styles';

interface ICheckboxProps
  extends Omit<IGridProps, 'isContainer' | 'isItem' | 'className'> {
  label: string;
  name: string;
  gridClassName?: string | undefined;
  onChange?: (newValue: boolean) => void;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  label,
  name,
  xs,
  sm,
  md,
  spacing = 2,
  gridClassName,
  onChange,
}) => {
  const { fieldName, defaultValue, registerField } = useField(name);
  const [selected, setSelected] = useState(defaultValue);
  const inputRef = useRef<any>(null);

  const handleClick = useCallback(() => {
    setSelected((oldValue: boolean) => {
      onChange && onChange(!oldValue);

      return !oldValue;
    })
  }, [onChange]);

  useEffect(() => {
    registerField({
      ref: inputRef.current,
      name: fieldName,
      getValue(ref: any) {
        return ref.value === 'true';
      },
      setValue: (_, newValue) => {
        if (typeof newValue === 'boolean') {
          setSelected(!!newValue);
        }
      },
      clearValue: (ref, newValue) => {
        ref.setInputValue(!!newValue);
      },
    });
  }, [fieldName, registerField, setSelected]);

  return (
    <Grid
      isItem
      spacing={spacing}
      xs={xs}
      md={md}
      sm={sm}
      className={gridClassName}
    >
      <Container isSelected={selected}>
        <input
          hidden
          ref={inputRef}
          type="checkbox"
          name={name}
          value={selected}
        />
        <div>
          <button onClick={handleClick}>
            {selected
              ? <RiCheckboxLine size={22} />
              : <RiCheckboxBlankLine size={22} />
            }
          </button>
        </div>
        <span>{label}</span>
      </Container>
    </Grid>
  );
}

export default Checkbox;
