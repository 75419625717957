import React from 'react';
// @ts-ignore
import { Menu } from '@szhsin/react-menu';
import { IoMdArrowDropdown } from 'react-icons/io';
import {
  AiOutlineVerticalRight,
  AiOutlineVerticalLeft,
  AiOutlineLeft,
  AiOutlineRight
} from 'react-icons/ai';

import {
  Container,
  RowsPerPage,
  MenuItem,
  Totalizer,
  Paginator
} from './styles';

interface IPaginationProps {
  pageIndex: number;
  pageSize: number;
  pageCount: number;
  pageOptions: any;
  canPreviousPage: boolean;
  canNextPage: boolean;
  previousPage(): void;
  nextPage(): void;
  setPageSize(size: number): void;
  gotoPage(page: number): void;
}

const Pagination: React.FC<IPaginationProps> = ({
  pageIndex,
  pageSize,
  pageCount,
  pageOptions,
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  setPageSize,
  gotoPage,
}) => {
  return (
    <Container>
      <RowsPerPage>
        <span>Linhas por página</span>

        <Menu
          align="start"
          menuButton={
            <div className="select-button">
              <span>{pageSize}</span>
              <IoMdArrowDropdown />
            </div>
          }
          offsetY={3}
          styles={{
            minWidth: 40,
            zIndex: 1500
          }}
        >
          <MenuItem disabled={pageSize === 5} onClick={() => setPageSize(5)}>
            <span>5</span>
          </MenuItem>
          <MenuItem disabled={pageSize === 10} onClick={() => setPageSize(10)}>
            <span>10</span>
          </MenuItem>
          <MenuItem disabled={pageSize === 20} onClick={() => setPageSize(20)}>
            <span>20</span>
          </MenuItem>
        </Menu>
      </RowsPerPage>

      <Totalizer>
        <span>
          Página {pageIndex + 1} de {pageOptions?.length || 0}
        </span>
      </Totalizer>

      <Paginator>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <AiOutlineVerticalRight size={19} />
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          <AiOutlineLeft size={19} />
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          <AiOutlineRight size={19} />
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <AiOutlineVerticalLeft size={19} />
        </button>
      </Paginator>
    </Container>
  );
}

export default Pagination;
