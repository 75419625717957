import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { OptionTypeBase } from 'react-select';
import Select, { Props as AsyncProps } from 'react-select/async';
import { useField } from '@unform/core';

import { getCurrentTheme } from "../../../styles/theme";
import { useRequest } from "../../../hooks/RequestContext";

import Grid, { IGridProps } from "../../Grid";

import { Container } from './styles';

interface IOption {
  label: string;
  value: string | number;
}

interface IAsyncOption {
  options: IOption[];
}

type TAsyncProps = Omit<AsyncProps<OptionTypeBase, false | true>, "loadOptions">;

interface Props
  extends TAsyncProps,
    Omit<IGridProps, 'isContainer' | 'isItem' | 'className'> {
  name: string;
  label?: string;
  required?: boolean;
  gridClassName?: string | undefined;
  fetchURL: string;
  formatFetchResponse?: (data: any) => { data: any };
}

let debounceEvent: any = null;

const AsyncSelect: React.FC<Props> = ({
  name,
  label,
  placeholder,
  required,
  xs = 12,
  sm = 12,
  md = 12,
  spacing = 2,
  gridClassName,
  formatFetchResponse,
  fetchURL,
  ...rest
}) => {
  const [options, setOptions] = useState([] as IAsyncOption[]);
  const selectRef = useRef<any>(null);
  const currentTheme = useMemo(() => getCurrentTheme(), []);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const { get: requestGet } = useRequest();

  const formatResponse = useCallback(
    (response: any, callback?: any) => {
      let data;

      if (formatFetchResponse) {
        const { data: responseData } = formatFetchResponse(response);
        data = responseData;
      } else {
        data = response?.data;
      }

      const formattedResponse = data?.map((data: any) => ({
        label: data.name,
        value: data.id,
      }));

      const newOptions = [{ options: formattedResponse }];

      callback && callback(newOptions);
      setOptions(newOptions);
    },
    [formatFetchResponse]
  );

  const fetchOptions = useCallback(
    (inputValue?: any, callback?: any) => {
      clearTimeout(debounceEvent);

      debounceEvent = setTimeout(() => {
        try {
          requestGet(`${fetchURL}?sort=name&direction=asc&q=${inputValue || ""}`)
            .then((data) => formatResponse(data, callback))
            .catch((err) => err)
        } catch {}
      }, 250);
    },
    [requestGet, fetchURL, formatResponse]
  );

  useEffect(() => {
    registerField({
      ref: selectRef.current,
      name: fieldName,
      setValue(ref: any, value) {
        if (value !== undefined) {
          ref.select.select.setValue(value);
        }
      },
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }

          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value,
          );
        }

        if (!ref.select.state.value) {
          return null;
        }

        return ref.select.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  useEffect(() => {
    if (!rest.defaultOptions) {
      fetchOptions();
    }
  }, [rest.defaultOptions, fetchOptions]);

  return (
    <Grid
      isItem
      xs={xs}
      sm={sm}
      md={md}
      spacing={spacing}
      className={gridClassName}
    >
      <Container>
        {label && (
          <label className="label">
            {`${label} ${required ? '*' : ''}`}
          </label>
        )}

        <Select
          isClearable
          isSearchable
          loadingMessage={() => "Carregando..."}
          noOptionsMessage={({ inputValue }) => {
            return !!inputValue
              ? "Ops, nenhuma informação encontrada"
              : "Digite alguma coisa para pesquisar";
          }}
          defaultOptions={options}
          {...rest}
          ref={selectRef}
          defaultValue={defaultValue}
          classNamePrefix="react-async-select"
          placeholder={placeholder || "Selecione uma opção"}
          loadOptions={fetchOptions}
          styles={{
            input: (style) => ({
              ...style,
              color: currentTheme.colors.text.title,
              fontSize: "0.9rem",
              fontWeight: 500,
            }),
            singleValue: (singleValueStyle) => ({
              ...singleValueStyle,
              color: currentTheme.colors.text.title,
              fontSize: "0.9rem",
              fontWeight: 500,
            }),
            placeholder: (placeholderStyle) => ({
              ...placeholderStyle,
              color: currentTheme.colors.text.p,
              fontSize: "0.8rem",
              fontWeight: 500,
            }),
            menu: (menuStyle) => ({
              ...menuStyle,
              top: 43,
              zIndex: 1600,
            }),
            menuList: (menuListStyle) => ({
              ...menuListStyle,
              fontSize: "0.8rem",
            }),
            control: () => ({
              display: "flex",
              flexDirection: "row",
              border: `1px solid ${error ? currentTheme.colors.red : "#cad1d7"}`,
              color: currentTheme.colors.text.title,
              width: "100%",
              height: 45,
              borderRadius: 5,
              "&:focus-within": {
                border: `1px solid ${error ? currentTheme.colors.red : currentTheme.colors.primary}`,
              },
              "&:hover": {
                border: `1px solid ${error ? currentTheme.colors.red : currentTheme.colors.primary}`,
              },
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#dedede",
              primary50: "#dedede",
              primary: currentTheme.colors.primary,
              neutral0: currentTheme.colors.white,
              neutral50: "#dedede",
              neutral60: error ? currentTheme.colors.red : currentTheme.colors.primary,
              neutral80: "#656565",
            },
          })}
        />

        {!!error && <label className="error">{error}</label>}
      </Container>
    </Grid>
  );
};
export default AsyncSelect;
