export function formatPhone(phone: string) {
  const isCellPhone = phone.length === 11;
  if (isCellPhone) {
    return phone
      .replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
  }

  return phone
    .replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
}
