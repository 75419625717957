import React, { useMemo } from 'react';

import { usersRouteApi } from '../../routes/config/api';
import {
  showUserRoute,
  editUserRoute,
  createUserRoute
} from '../../routes/config';
import getUserType from '../../utils/getUserType';
import { useAuth } from '../../hooks/AuthContext';
import { isRootUser } from '../../utils/userTypes';

import LayoutList from '../../components/Layouts/List';
import { TTableColumn } from '../../components/Table';

import { UserType } from './styles';

export const List: React.FC = () => {
  const { user } = useAuth();
  const tableColumns: TTableColumn[] = useMemo(() => {
    const columns: TTableColumn[] = [
      { Header: 'Nome', accessor: 'name', minWidth: 300 }
    ];

    if (isRootUser(user.type)) {
      columns.push({
        Header: 'Empresa',
        accessor: 'company',
        minWidth: 300,
        Cell: ({ row }) => row.values.company.name,
      });
    }

    columns.push({ Header: 'Usuário', accessor: 'username', width: 200 });
    columns.push({
      Header: 'Tipo',
      accessor: 'type',
      width: 150,
      Cell({ row }) {
        return (
          <UserType type={row.values.type}>
            <span>{getUserType(row.values.type)}</span>
          </UserType>
        )
      }
    });
    columns.push({
      Header: 'Perfil',
      accessor: 'profile',
      width: 200,
      Cell: ({ row }) => row.values.profile.name,
    });

    return columns;
  }, [user]);

  return (
    <LayoutList
      breadcrumbs={[{ text: 'Usuários' }]}
      navMenuProps={{
        visible: true,
        newRoute: createUserRoute.path,
      }}
      tableProps={{
        title: 'Listagem de Usuários',
        actions: {
          enable: true,
          showRoute: showUserRoute,
          editRoute: editUserRoute,
          deleteRoute: usersRouteApi.path,
        },
        columns: tableColumns,
        fetch: {
          url: usersRouteApi.path,
        }
      }}
    />
  );
}
