import styled from 'styled-components';

interface IContainer {
  isSelected: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    color: ${
      props => props.isSelected
        ? props.theme.colors.primary
        : '#cad1d7'
    };
    transition: background-color 150ms ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.black_100};
    }
  }

  span {
    display: flex;
    flex: 1;
    font-size: 0.9rem;
    padding: 2px 0 0 2px;
  }
`;
