import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 16px;

  > *:not(:first-child) {
    margin-left: 8px;
  }

  @media(max-width: 600px) {
    margin-top: 0;
    margin-bottom: 16px;

    .delete-button {
      width: 100%;
      padding: 0;
    }

    a {
      width: 100%;
      text-decoration: none;

      button {
        width: 100%;
      }
    }
  }
`;
