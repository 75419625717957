import styled from 'styled-components';

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  padding: 0 6px;

  img {
    max-width: 380px;
  }

  span {
    margin-top: -50px;
    font-size: 0.9rem;
    font-weight: 500;
  }
`;
