import React from "react";

import LayoutBase, { IBaseLayoutProps } from "../Base";
import CardForm, { ICardFormProps } from "../../CardForm";
import FooterActions, { FooterActionsEmpty, IFooterActionsProps } from "../../FooterActions";

interface LayoutShowProps extends Omit<IBaseLayoutProps, 'navMenuProps'> {
  cardProps: ICardFormProps;
  footerActionsProps: IFooterActionsProps;
}

const Edit: React.FC<LayoutShowProps> = ({
  breadcrumbs,
  cardProps,
  footerActionsProps,
  children,
}) => {
  return (
    <LayoutBase
      breadcrumbs={breadcrumbs}
      navMenuProps={{
        visible: false
      }}
    >
      <CardForm {...cardProps}>
        {children}
      </CardForm>

      <FooterActionsEmpty />
      <FooterActions {...footerActionsProps} />
    </LayoutBase>
  );
};

export default Edit;
