import styled, { css } from 'styled-components';

interface IThProps {
  isFixed?: boolean;
}

interface ITdProps {
  isFixed?: boolean;
}

const thFixedColumnCss = css`
  z-index: 300;
  right: 0;
  position: sticky;
  background-clip: padding-box;
  border-left: 1px solid #e8ecef;
`;

const tdFixedColumnCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
  right: 0;
  position: sticky;
  background-clip: padding-box;
  background-color: ${props => props.theme.colors.white};
  border-left: 1px solid #e8ecef;
  padding: 0.67rem 1.5rem;
`;

export const Container = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;

  .align-items-center {
    align-items: center !important;
  }

  .table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const Table = styled.table`
  display: table;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

export const Tr = styled.tr`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  position: relative;

  [aria-sort] {
    cursor: pointer;
  }

  :last-child {
    td {
      border-bottom: none;
    }
  }

  .th-actions {
    text-align: center;
  }
`;

export const Th = styled.th<IThProps>`
  display: table-cell;
  vertical-align: inherit;
  font-weight: 600;
  font-size: 0.75rem;
  text-align: inherit;
  padding: 0.7rem 1.5rem;
  background-color: #f5f9fc;
  color: #505050;
  text-align: start;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props => !!props.isFixed && thFixedColumnCss}
`;

export const TableBody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;

  .no-data {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  :last-child {
    border-bottom: 1px solid #e8ecef;
  }
`;

export const Td = styled.td<ITdProps>`
  display: flex;
  align-items: center;
  padding: 0.7rem 1.5rem;
  font-size: 0.9rem !important;
  font-weight: 400;
  border-bottom: 1px solid #e8ecef;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props => !!props.isFixed && tdFixedColumnCss}
`;
