import styled from 'styled-components';
import { Form as UForm } from '@unform/web';

export const Form = styled(UForm)`
  width: 100%;
`;

interface IProfilePermission {
  hasPermission: boolean;
}

export const ProfilePermission = styled.div<IProfilePermission>`
  display: flex;
  justify-content: center;
  width: 100px;
  padding: 4px;
  border-radius: 2px;
  border: 1px solid ${props => props.hasPermission
    ? props.theme.colors.green
    : props.theme.colors.peach
  };
  margin: auto;
  font-size: 0.85rem;
  font-weight: 500;
  color: ${props => props.hasPermission
    ? props.theme.colors.green
    : props.theme.colors.peach
  };
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .table-container {
    padding: 0 16px 32px 16px;
  }
`;
