import React, { useState, useEffect, useMemo } from 'react';
import { FormHandles, Scope } from '@unform/core';

import { IModule } from '../types';
import { biProvidersRouteApi, companiesRouteApi } from '../../../routes/config/api';
import { isRootUser } from '../../../utils/userTypes';
import { useAuth } from '../../../hooks/AuthContext';
import { useRequest } from '../../../hooks/RequestContext';

import Grid from '../../../components/Grid';
import InputText from '../../../components/Input/Text';
import InputAsyncSelect from '../../../components/Input/AsyncSelect';
import Select from '../../../components/Input/Select';
import InputCheckbox from '../../../components/Input/Checkbox';
import SelectBIProviderModuleModal from '../SelectBIProviderModuleModal';
import ModuleImage from '../ModuleImage';

import { Form } from '../styles';

interface IFormProps {
  initialData?: IModule;
  formRef: React.RefObject<FormHandles>;
}

const CustomForm: React.FC<IFormProps> = ({
  formRef,
  initialData,
}) => {
  const [datasetRequired, setDatasetRequired] = useState(true);
  const [biProviderId, setBIProviderId] = useState(null);
  const [sharedCompanies, setSharedCompanies] = useState<any[]>([]);
  const { user } = useAuth();
  const canShareModule = useMemo(() => {
    return isRootUser(user.type);
  }, [user]);
  const { get: requestGet } = useRequest();

  useEffect(() => {
    if (initialData) {
      formRef.current?.setData(initialData);
    }
  }, [initialData, formRef, requestGet]);

  useEffect(() => {
    async function findCompanies() {
      const response = await requestGet(`${companiesRouteApi.path}?per_page=10000`);
      const formattedSharedCompanies = response.data?.map((shareModule: any) => ({
        label: shareModule.company_name,
        value: shareModule.id,
      }));

      setSharedCompanies(formattedSharedCompanies);
    };

    canShareModule && findCompanies();
  }, [canShareModule, requestGet]);

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <Grid isContainer>
        <InputAsyncSelect
          required
          name="bi_provider"
          label="Provedor de dados"
          placeholder="Selecione um provedor"
          fetchURL={biProvidersRouteApi.path}
          xs={6}
          onChange={(item: any) => {
            setBIProviderId(item?.value || null);
          }}
        />
        <Grid isItem xs={6} spacing={2}>
          <SelectBIProviderModuleModal
            biProviderId={biProviderId}
            onSelect={(report) => {
              formRef.current?.setFieldValue('name', report.name);
              formRef.current?.setFieldValue('powerbi_module.group_id', report.group_id);
              formRef.current?.setFieldValue('powerbi_module.report_id', report.id);
              formRef.current?.setFieldValue('powerbi_module.dataset_id', report.dataset_id);
            }}
          />
        </Grid>

        <InputText
          required
          label="Nome"
          name="name"
          xs={6}
        />

        {canShareModule && (
          <Select
            isMulti
            closeMenuOnSelect={false}
            name="shared_modules"
            label="Compartilhar este módulo com alguma empresa?"
            xs={6}
            options={sharedCompanies}
          />
        )}

        <Scope path="powerbi_module">
          <InputText
            required
            label="Group Id"
            name="group_id"
            xs={6}
          />
          <InputText
            required
            label="Report Id"
            name="report_id"
            xs={6}
          />

          {!canShareModule && (
            <InputText
              required={datasetRequired}
              label="Dataset Id"
              name="dataset_id"
              xs={6}
            />
          )}

          <Grid isItem xs={6} spacing={0}>
            {canShareModule && (
              <InputText
                required={datasetRequired}
                label="Dataset Id"
                name="dataset_id"
              />
            )}

            <InputCheckbox
              label="Mostrar painel de filtros"
              name="filter_pane_enabled"
            />
            <InputCheckbox
              label="Mostrar painel de navegação"
              name="nav_content_pane_enabled"
            />
            <InputCheckbox
              label="Usar identidade"
              name="identities"
              onChange={(value: boolean) => {
                setDatasetRequired(!value)
              }}
            />
          </Grid>
        </Scope>

        <ModuleImage />
      </Grid>
    </Form>
  );
}

export default CustomForm;
