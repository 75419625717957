import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 0 2rem 0 rgba(136,152,170,0.15);
  word-wrap: break-word;
  border: 1px solid ${props => props.theme.colors.black_050};
  border-radius: 4px;

  .header {
    background-color: ${props => props.theme.colors.white};
    font-size: 1.1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  .loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 8px 0;

    .spinner {
      height: 40px !important;
      fill: ${props => props.theme.colors.primary} !important;
    }
  }

  .no-data {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    text-align: center;

    span {
      font-size: 0.9rem;
    }
  }
`;
