import styled from 'styled-components';
import { transparentize } from 'polished';
// @ts-ignore
import { MenuItem as SZHMenuItem } from '@szhsin/react-menu';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 60px;
  padding: 0 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.backgrond};
  z-index: 1000;

  .logo-container {
    height: 50%;

    img {
      width: 125px;
      margin-left: -6px;
    }

    .short-logo {
      display: none;
      height: 100%;
      width: auto;
    }

    @media(max-width: 500px) {
      .logo {
        display: none;
      }

      .short-logo {
        display: flex;
      }
    }
  }


  .menu-options {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    .search-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: ${props => props.theme.colors.white};
      width: 190px;
      height: 100%;
      padding: 5px 0;
      border-radius: 4px;
      border: 1px solid #cad1d7;
      transition: color 100ms ease-in-out;

      &:hover {
        border-color: ${props => props.theme.colors.text.p};
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        color: ${props => props.theme.colors.text.cream};
      }

      input {
        width: 100%;
        border: none;
        font-size: 0.85rem;
        color: ${props => props.theme.colors.text.title};

        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: ${props => props.theme.colors.text.cream};
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: ${props => props.theme.colors.text.cream};
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
          color: ${props => props.theme.colors.text.cream};
        }
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        width: 60px;
        color: ${props => props.theme.colors.text.cream};
      }

      @media(max-width: 500px) {
        margin-left: 16px;
        width: 100%;
      }
    }

    .menu-admin {
      display: flex;
      padding: 0 8px;
      justify-content: center;
      align-items: center;

      button {
        display: flex;
        border: none;
        background: transparent;
        color: ${props => props.theme.colors.text.cream};
        transition: all 125ms ease-in-out;
        transition-property: background-color, color;
        padding: 6px;
        border-radius: 50%;

        &:hover {
          background-color: ${props => transparentize(0.87, props.theme.colors.primary)};
          color: ${props => props.theme.colors.primary};
        }
      }
    }

    .menu-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      min-width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: ${props => props.theme.colors.primary};
      cursor: pointer;

      span {
        color: ${props => props.theme.colors.white};
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }
`;

export const MenuItem = styled(SZHMenuItem)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text.title};
  font-size: 0.9rem;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.colors.text.title};
    text-decoration: none;
    width: 100%;

    .icon-link {
      margin-top: 2px;
    }
  }

  span {
    margin-left: 8px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }

  &:active {
    -webkit-tap-highlight-color: transparent;
    background-color: ${
      props => transparentize(0.84, props.theme.colors.primary)
    };

    svg, span {
      color: ${ props => props.theme.colors.primary};
      font-weight: 500;
    }
  }
`;
