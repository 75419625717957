import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import {
  IEmbedConfiguration,
  IQnaEmbedConfiguration,
  IVisualEmbedConfiguration,
  models,
} from "powerbi-client";

import { useRequest } from '../../hooks/RequestContext';
import { embeddedRouteApi } from '../../routes/config/api';
import { dashboardRoute } from '../../routes/config';

import { Container, Error } from "./styles";

type IEmbedConfig =
  | IEmbedConfiguration
  | IQnaEmbedConfiguration
  | IVisualEmbedConfiguration;

interface IResponse {
  id: string;
  report_name: string;
  accessToken: string;
  embedUrl: string;
  filterPaneEnabled: boolean;
  navContentPaneEnabled: boolean;
  permissions: models.Permissions;
}

const generateKey = () => Math.random().toString();
const defaultEmbedConfig: IEmbedConfig = {
  id: "",
  type: "report",
  accessToken: "",
  embedUrl: "",
  tokenType: 1, // Embed
  permissions: 0, // Read
  settings: {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    layoutType: 0, // Master
  },
};

const EmbeddedDashboard: React.FC = () => {
  const [embedConfig, setEmbedConfig] = useState<IEmbedConfig>(
    defaultEmbedConfig
  );
  const [, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [containerKey, setContainerKey] = useState(generateKey());
  const { get: requestGet } = useRequest();
  const {
    location: { pathname },
    push: locationPush,
  } = useHistory();

  const findPowerBIInfo = useCallback(
    async (moduleId: number) => {
      try {
        setLoading(true);
        setError(false);

        const response = (await requestGet(
          `${embeddedRouteApi.path}/${moduleId}`
        )) as IResponse;

        if (!!response) {
          if (response.report_name) {
            document.title = `${response.report_name} | Datasim`;
          }

          setEmbedConfig((oldData: any) => {
            const {
              filterPaneEnabled = false,
              navContentPaneEnabled = false,
              ...restResponse
            } = response;

            const desktopMode = 0;
            // const mobilePortraitMode = 2;
            const settings = {
              ...oldData.settings,
              filterPaneEnabled,
              navContentPaneEnabled,
              layoutType: desktopMode,
            };

            return { ...oldData, ...restResponse, settings };
          });
          setContainerKey(generateKey());
        }
      } catch {
        setError(true);
      } finally {
        setTimeout(() => setLoading(false), 5000);
      }
    },
    [requestGet]
  );

  const handleClick = useCallback(async () => {
    const moduleId = pathname.split("/").pop();

    if (moduleId) {
      findPowerBIInfo(parseInt(moduleId as any));
    } else {
      setError(true);
    }
  }, [pathname, findPowerBIInfo]);

  useEffect(() => {
    const moduleId = pathname.split("/").pop();

    if (moduleId) {
      findPowerBIInfo(parseInt(moduleId as any));
    } else {
      alert("Acesso negado, contate o Administrador!");
      locationPush(dashboardRoute.path);
    }
  }, [findPowerBIInfo, locationPush, pathname]);

  if (error) {
    return (
      <Error>
        <h3>Ops, aconteceu algum problema ao buscar as informações!</h3>
        <span>Qualquer dúvida, contate o Administrador.</span>

        <button onClick={handleClick}>Recarregar página</button>
      </Error>
    );
  }

  return (
    <Container key={containerKey}>
      <PowerBIEmbed
        embedConfig={embedConfig}
        cssClassName={"powerbi-report-embed"}
      />
    </Container>
  );
};

export default EmbeddedDashboard;
