import React from "react";

import Grid, { IGridProps } from "../../Grid";

import { Container } from "./styles";

export interface IInputReadProps
  extends Omit<IGridProps, 'isContainer' | 'isItem'> {
  label: string;
  value: string;
}

const InputRead: React.FC<IInputReadProps> = ({
  label,
  value,
  spacing = 2,
  ...restPtops
}) => (
  <Grid {...restPtops} isItem spacing={spacing}>
    <Container>
      <label className="label">
        {label}
      </label>

      <div className="input">
        <span>{value}</span>
      </div>
    </Container>
  </Grid>
);

export default InputRead;
