import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { IUser } from './types';
import { useRequest } from '../../hooks/RequestContext';
import { usersRouteApi } from '../../routes/config/api';
import { listUsersRoute, showUserRoute } from '../../routes/config';
import isBlank from '../../utils/isBlank';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';

import LayoutEdit from '../../components/Layouts/Edit';
import Form from './Form';

interface IEditParams {
  id: string;
}

export const Edit: React.FC = () => {
  const [initialData, setInitialData] = useState<IUser>({} as IUser);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { id: userId } = useParams<IEditParams>();
  const history = useHistory();
  const { get: requestGet, put: requestPut } = useRequest();

  const updateUser = useCallback(
    async (data: any) => {
      try {
        setIsSaving(true);

        const formattedData = {
          ...data,
          profile_id: data.profile,
          type: initialData.type,
          organization_id: (initialData as any).organization_id,
        };

        if (data.password && data.password_confirmation) {
          Object.assign(formattedData, {
            new_password: data.password,
          });
        }

        const response = await requestPut(
          `${usersRouteApi.path}/${userId}`,
          formattedData
        );

        toast.success("Usuário atualizado com sucesso!");
        history.push(showUserRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível atualizar o usuário!");
      } finally {
        setIsSaving(false);
      }
    },
    [initialData, requestPut, userId, history]
  );

  const handleSubmit = useCallback(
    async () => {
      try {
        formRef.current?.setErrors({});

        const data = formRef.current?.getData() as any;
        const validatePassword =
          !!data.password || !!data.password_confirmation
            ? {
                password: Yup.string()
                  .required("Senha deve ter no mínimo 8 digítos")
                  .min(8, "No mínimo 8 digitos"),
                password_confirmation: Yup.string()
                  .required("Senha deve ter no mínimo 8 digítos")
                  .min(8, "No mínimo 8 digitos")
                  .oneOf(
                    [Yup.ref("password"), ""],
                    "Senha de confirmação incorreta"
                  ),
              }
            : {};
        const schema = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          username: Yup
            .string()
            .min(5, "No mínimo 5 caracteres")
            .required('Campo obrigatório'),
          email: Yup.string().email('E-mail inválido'),
          powerbi_user: Yup.string().required('Campo obrigatório'),
          profile: Yup.mixed().required('Campo obrigatório'),
          ...validatePassword,
        });

        await schema.validate(data, { abortEarly: false });

        updateUser(data);
      } catch (err) {
        const validationErrors = getValidationsErrors(err);
        formRef.current?.setErrors(validationErrors);
      }
    },
    [updateUser]
  );

  useEffect(() => {
    async function findUser() {
      try {
        const response = await requestGet(`${usersRouteApi.path}/${userId}`);
        if (!isBlank(response)) {
          setInitialData({
            ...response,
            profile: {
              label: response.profile?.name,
              value: response.profile?.id,
            }
          });
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findUser();
  }, [requestGet, userId]);

  return (
    <LayoutEdit
      breadcrumbs={[
        { text: 'Usuários', path: listUsersRoute.path },
        { text: 'Editar Usuário' }
      ]}
      cardProps={{
        title: 'Alterar informações do usuário',
        noData: isBlank(initialData),
        loading,
      }}
      footerActionsProps={{
        onCancelRoute: listUsersRoute.path,
        onSubmit: handleSubmit,
        loading: isSaving || loading,
      }}
    >
      <Form
        formRef={formRef}
        initialData={initialData}
      />
    </LayoutEdit>
  );
}
