import React from "react";

import LayoutBase, { IBaseLayoutProps } from "../Base";
import CardForm, { ICardFormProps } from "../../CardForm";

interface ILayoutShowProps extends IBaseLayoutProps {
  cardProps: ICardFormProps;
}

const Show: React.FC<ILayoutShowProps> = ({
  breadcrumbs,
  navMenuProps,
  cardProps,
  children,
}) => {
  return (
    <LayoutBase breadcrumbs={breadcrumbs} navMenuProps={navMenuProps}>
      <CardForm {...cardProps}>
        {children}
      </CardForm>
    </LayoutBase>
  );
};

export default Show;
