import Route from "../../services/route";

const rootPath = "/api/v1";

export const signInRouteApi = new Route('auth');

export const userProfileRouteApi = new Route(`${rootPath}/user-profile`);

export const usersRouteApi = new Route(`${rootPath}/users`);

export const profilesRouteApi = new Route(`${rootPath}/profiles`);

export const modulesRouteApi = new Route(`${rootPath}/modules`);

export const biProvidersRouteApi = new Route(`${rootPath}/bi-providers`);

export const companiesRouteApi = new Route(`${rootPath}/companies`);

export const embeddedRouteApi = new Route(`${rootPath}/embedded`);

export const testConnectionRouteApi = new Route(`${rootPath}/test-connection`);

export const myCompanyRouteApi = new Route(`${rootPath}/my-company`);

export const getReportsFromBIProviderRouteApi = new Route(`${rootPath}/get-reports-from-bi-provider/:id`);
