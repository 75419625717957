import React from "react";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import MyCompany from "../../pages/MyCompany";

export const myCompanyRoute = new Route("/minha-empresa");

export const MyCompanyRouteComponent: React.FC = () => (
  <RouteWrapper
    exact
    isPrivate
    path={myCompanyRoute.path}
    component={MyCompany}
  />
);
