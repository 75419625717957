import React, { useEffect } from 'react';

import CardForm from '../../components/CardForm';
import InputRead from '../../components/Input/Read';
import Breadcrumbs from "../../components/Breadcrumbs";

import getUserType from '../../utils/getUserType';
import { useAuth } from '../../hooks/AuthContext';
import { useBreadcrumbs } from '../../hooks/BreadcrumbsContext';
import { dashboardRoute } from '../../routes/config';

import { Container } from './styles';

const UserProfile: React.FC = () => {
  const { user } = useAuth();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      { text: 'Ínicio', path: dashboardRoute.path },
      { text: 'Minha Conta' }
    ]);
  }, [setBreadcrumbs]);

  return (
    <Container>
      <Breadcrumbs className="breadcrumbs" />
      <CardForm title="Meu perfil" noData={false} loading={false}>
        <InputRead label="Nome" value={user.name} />
        <InputRead label="Empresa" value={user.company.name} />
        <InputRead label="Usuário" value={user.username} xs={6} />
        <InputRead label="E-mail" value={user.email} xs={6} />
        <InputRead label="Usuário do Power BI" value={user.powerbi_user} xs={6} />
        <InputRead label="Tipo" value={getUserType(user.type as any)} xs={6} />
      </CardForm>
    </Container>
  );
}

export default UserProfile;
