import React, { useState, useEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import swal from 'sweetalert';
import { stringify } from 'querystring';

import { IBIProvider } from '../types';
import { testConnectionRouteApi } from '../../../routes/config/api';
import { useRequest } from '../../../hooks/RequestContext';

import Grid from '../../../components/Grid';
import InputText from '../../../components/Input/Text';
import Button from '../../../components/Button';

import { Form } from '../styles';

interface IFormProps {
  bi_provider_id?: number;
  isCreatePage?: boolean;
  initialData?: IBIProvider;
  formRef: React.RefObject<FormHandles>;
}

const CustomForm: React.FC<IFormProps> = ({
  bi_provider_id,
  formRef,
  isCreatePage,
  initialData,
}) => {
  const [loading, setLoading] = useState(false);
  const { get: requestGet } = useRequest();

  const handleTestConnection = useCallback(async () => {
    try {
      const data = formRef.current?.getData() as any;
      let params = {};

      if (bi_provider_id) {
        Object.assign(params, { id: bi_provider_id });
      }

      if (data.account_id) {
        Object.assign(params, { account_id: data.account_id });
      }

      if (data.auth_client_id) {
        Object.assign(params, { auth_client_id: data.auth_client_id });
      }

      if (data.auth_client_secret) {
        Object.assign(params, { auth_client_secret: data.auth_client_secret });
      }

      setLoading(true);

      const uri = `${testConnectionRouteApi.path}?${stringify(params)}`;
      const responseSuccess = await requestGet(uri);

      if (responseSuccess) {
        swal("Muito bom!", "Conexão realizada com sucesso.", "success");
      } else {
        throw new Error('Ops, something went wrong!');
      }
    } catch {
      swal("Ops, algo deu errado!", "Verifique os dados e tente novamente.", "error");
    } finally {
      setLoading(false);
    }
  }, [formRef, bi_provider_id, requestGet]);

  useEffect(() => {
    if (initialData) {
      formRef.current?.setData(initialData);
    }
  }, [initialData, formRef]);

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <Grid isContainer>
        <InputText
          required
          label="Nome"
          name="name"
          xs={6}
        />
        <InputText
          required={isCreatePage}
          label="Power BI Account Id"
          name="account_id"
          xs={6}
        />
        <InputText
          required={isCreatePage}
          label="Power BI Client Id"
          name="auth_client_id"
          xs={6}
        />
        <InputText
          required={isCreatePage}
          label="Power BI Client Secret"
          name="auth_client_secret"
          xs={6}
        />

        <Grid isItem spacing={2}>
          <Button
            text="Testar conexão"
            option="info"
            disabled={loading}
            onClick={handleTestConnection}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

export default CustomForm;
