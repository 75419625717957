import React, { useState, createContext, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';

import Request from '../services/request';
import { signInRouteApi } from '../routes/config/api';

interface IUser {
  id: string,
  name: string,
  email: string,
  username: string,
  powerbi_user: string,
  type: string,
  is_owner: boolean,
  company: {
    id: string,
    name: string,
  }
  profile: {
    id: number,
    name: string,
    can_manage_users: boolean,
    can_manage_modules: boolean,
    can_manage_profiles: boolean,
    can_manage_bi_providers: boolean,
  },
}

interface IAuth {
  signed: boolean;
  token: string | null;
  user: IUser;
}

interface ISignInProps {
  username: string;
  password: string;
}

export interface IAuthContextProps {
  signed: boolean;
  token: string | null;
  user: IUser;
  signIn(props: ISignInProps): Promise<boolean>;
  signOut(): void;
  setUser(user: IUser): void;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

export const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<IAuth>(() => {
    const token = localStorage.getItem('@App:auth:token');

    Request.setHeader('Authorization', `Bearer ${token}`);

    return {
      signed: !!token,
      token: token,
      user: {} as IUser,
    };
  });

  const signIn = useCallback(async (data: ISignInProps): Promise<boolean> => {
    try {
      const response = await Request.post(signInRouteApi.path, data);

      localStorage.setItem('@App:auth:token', response.token);
      Request.setHeader('Authorization', `Bearer ${response.token}`);

      setAuth((oldAuth) => ({
        ...oldAuth,
        ...response,
        signed: !!response.token,
      }));

      return true;
    } catch (err) {
      toast.error('Usuário ou senha incorretos');
      return false;
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear();
    setAuth({
      signed: false,
      token: null,
      user: {} as IUser,
    });
  }, []);

  const setUser = useCallback((user) => {
    setAuth((oldData) => ({
      ...oldData,
      user,
    }));
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, signIn, signOut, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export default AuthContext;
