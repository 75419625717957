import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IUser } from './types';
import { listUsersRoute, editUserRoute } from '../../routes/config';
import { usersRouteApi } from '../../routes/config/api';
import { useRequest } from '../../hooks/RequestContext';
import isBlank from '../../utils/isBlank';
import getUserType from '../../utils/getUserType';

import LayoutShow from '../../components/Layouts/Show';
import InputRead from '../../components/Input/Read';

interface IShowParams {
  id: string;
}

export const Show: React.FC = () => {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [loading, setLoading] = useState(true);
  const { id: userId } = useParams<IShowParams>();
  const { get: requestGet } = useRequest();

  useEffect(() => {
    async function findUser() {
      try {
        const response = await requestGet(`${usersRouteApi.path}/${userId}`);
        if (!isBlank(response)) {
          setUser({
            ...response,
            type: getUserType(response.type)
          });
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findUser();
  }, [requestGet, userId]);

  return (
    <LayoutShow
      breadcrumbs={[
        { text: 'Usuários', path: listUsersRoute.path },
        { text: 'Visualizar Usuário' }
      ]}
      navMenuProps={{
        deleteRoute: {
          route: `${usersRouteApi.path}/${userId}`,
          redirect: listUsersRoute.path,
        },
        editRoute: editUserRoute.build({ id: userId }),
      }}
      cardProps={{
        title: 'Visão geral do usuário',
        noData: isBlank(user),
        loading,
      }}
    >
      <InputRead label="Nome" value={user.name} />
      <InputRead label="Usuário" value={user.username} xs={6} />
      <InputRead label="E-mail" value={user.email} xs={6} />
      <InputRead label="Usuário do PowerBI" value={user.powerbi_user} xs={6} />
      <InputRead label="Tipo" value={user.type} xs={6} />
      <InputRead label="Empresa" value={user.company?.name} xs={6} />
      <InputRead label="Perfil" value={user.profile?.name} xs={6} />
    </LayoutShow>
  );
}
