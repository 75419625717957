import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import { API_URL } from "../utils/constants";

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  UNPROCESSABLE_ENTITY: 422,
};

const api: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  timeoutErrorMessage:
    "Houston we have a problem with the connection. Try again later",
});

class Request {
  public static CancelToken = axios.CancelToken;

  public static isCancel = axios.isCancel;

  public static async get<T = any, R = T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api
      .get(url, Request.getConfig(config))
      .then((res) => Promise.resolve(res.data))
      .catch((err) => Promise.reject(err));
  }

  public static async post<T = any, R = T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api
      .post(url, data, Request.getConfig(config))
      .then((res) => Promise.resolve(res.data))
      .catch((err) => Promise.reject(err));
  }

  public static async put<T = any, R = T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api
      .put(url, data, Request.getConfig(config))
      .then((res) => Promise.resolve(res.data))
      .catch((err) => Promise.reject(err));
  }

  public static async del<T = any, R = T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api
      .delete(url, Request.getConfig(config))
      .then((res) => Promise.resolve(res.data))
      .catch((err) => Promise.reject(err));
  }

  public static setHeader(name: string, value: string): void {
    api.defaults.headers[name] = value;
  }

  private static getConfig(config: any): object {
    return {
      responseType: "json",
      headers: {
        Authorization: api.defaults.headers.Authorization,
        "Content-Type": "application/json",
      },
      ...config,
    };
  }
}

export default Request;
