import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { IProfile } from './types';
import { useRequest } from '../../hooks/RequestContext';
import { profilesRouteApi } from '../../routes/config/api';
import { listProfilesRoute, showProfileRoute } from '../../routes/config';
import isBlank from '../../utils/isBlank';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';

import LayoutEdit from '../../components/Layouts/Edit';
import Form from './Form';

interface IEditParams {
  id: string;
}

export const Edit: React.FC = () => {
  const [initialData, setInitialData] = useState<IProfile>({} as IProfile);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { id: profileId } = useParams<IEditParams>();
  const history = useHistory();
  const { get: requestGet, put: requestPut } = useRequest();

  const updateProfile = useCallback(
    async (data: any) => {
      try {
        setIsSaving(true);

        const formattedData = {
          name: data.name,
          can_manage_users: data.can_manage_users,
          can_manage_modules: data.can_manage_modules,
          can_manage_profiles: data.can_manage_profiles,
          can_manage_bi_providers: data.can_manage_bi_providers,
          profile_modules: data.modules.map((moduleId: number) => ({
            module_id: moduleId,
          })),
        }
        const response = await requestPut(
          `${profilesRouteApi.path}/${profileId}`,
          formattedData
        );

        toast.success("Perfil atualizado com sucesso!");
        history.push(showProfileRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível atualizar o perfil!");
      } finally {
        setIsSaving(false);
      }
    },
    [requestPut, profileId, history]
  );

  const handleSubmit = useCallback(
    async () => {
      try {
        formRef.current?.setErrors({});

        const data = formRef.current?.getData() as any;
        const schema = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          modules: Yup.mixed().required('Campo obrigatório'),
        });

        await schema.validate(
          {
            ...data,
            modules: data.modules?.length ? data.modules : null
          },
          { abortEarly: false }
        );

        updateProfile(data);
      } catch (err) {
        const validationErrors = getValidationsErrors(err);
        formRef.current?.setErrors(validationErrors);
      }
    },
    [updateProfile]
  );

  useEffect(() => {
    async function findProfile() {
      try {
        const response = await requestGet(`${profilesRouteApi.path}/${profileId}`);
        if (!isBlank(response)) {
          setInitialData({
            ...response,
            modules: response.profile_modules.map((profileModule: any) => ({
              label: profileModule.module.name,
              value: profileModule.module.id,
            })),
          });
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findProfile();
  }, [requestGet, profileId]);

  return (
    <LayoutEdit
      breadcrumbs={[
        { text: 'Perfis', path: listProfilesRoute.path },
        { text: 'Editar Perfil' }
      ]}
      cardProps={{
        title: 'Alterar informações do perfil',
        noData: isBlank(initialData),
        loading,
      }}
      footerActionsProps={{
        onCancelRoute: listProfilesRoute.path,
        onSubmit: handleSubmit,
        loading: isSaving || loading,
      }}
    >
      <Form formRef={formRef} initialData={initialData} />
    </LayoutEdit>
  );
}
