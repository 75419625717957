import React, { useState, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useRequest } from '../../hooks/RequestContext';
import { usersRouteApi } from '../../routes/config/api';
import { listUsersRoute, showUserRoute } from '../../routes/config';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';

import LayoutCreate from '../../components/Layouts/Create';

import Form from './Form';

export const Create: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { post: requestPost } = useRequest();
  const history = useHistory();

  const createUser = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const response = await requestPost(usersRouteApi.path, {
          ...data,
          type: 'user',
          profile_id: data.profile,
        });

        toast.success("Usuário criado com sucesso!");
        history.push(showUserRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível cadastrar este usuário!");
      } finally {
        setLoading(false);
      }
    },
    [requestPost, history]
  );

  const handleSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;
      const schema = Yup.object().shape({
        name: Yup.string().required("Campo obrigatório"),
        username: Yup.string()
          .required("Campo obrigatório")
          .min(5, "No mínimo 5 caracteres"),
        email: Yup.string().email('E-mail inválido'),
        powerbi_user: Yup.string().required("Campo obrigatório"),
        profile: Yup.mixed().required('Campo obrigatório'),
        password: Yup.string()
          .required("Senha deve ter no mínimo 8 digítos")
          .min(8, "No mínimo 8 digitos"),
        password_confirmation: Yup.string()
          .required("Senha deve ter no mínimo 8 digítos")
          .min(8, "No mínimo 8 digitos")
          .oneOf([Yup.ref("password"), ""], "Senha de confirmação incorreta"),
      });

      await schema.validate(data, { abortEarly: false });

      createUser(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [createUser]);

  return (
    <LayoutCreate
      breadcrumbs={[
        { text: 'Usuários', path: listUsersRoute.path },
        { text: 'Criar Usuário' }
      ]}
      cardProps={{
        title: 'Criar novo usuário',
        noData: false,
        loading: false,
      }}
      footerActionsProps={{
        onCancelRoute: listUsersRoute.path,
        onSubmit: handleSubmit,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutCreate>
  );
}
