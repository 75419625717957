import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IBIProvider } from './types';
import { listBIProvidersRoute, editBIProviderRoute } from '../../routes/config';
import { biProvidersRouteApi } from '../../routes/config/api';
import { useRequest } from '../../hooks/RequestContext';
import isBlank from '../../utils/isBlank';

import LayoutShow from '../../components/Layouts/Show';
import InputRead from '../../components/Input/Read';

interface IShowParams {
  id: string;
}

export const Show: React.FC = () => {
  const [biProvider, setBIProvider] = useState<IBIProvider>({} as IBIProvider);
  const [loading, setLoading] = useState(true);
  const { id: biProviderId } = useParams<IShowParams>();
  const { get: requestGet } = useRequest();

  useEffect(() => {
    async function findBIProvider() {
      try {
        const response = await requestGet(
          `${biProvidersRouteApi.path}/${biProviderId}`
        );
        if (!isBlank(response)) {
          setBIProvider(response);
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findBIProvider();
  }, [requestGet, biProviderId]);

  return (
    <LayoutShow
      breadcrumbs={[
        { text: 'Provedores de Dados', path: listBIProvidersRoute.path },
        { text: 'Visualizar Provedor de Dados' }
      ]}
      navMenuProps={{
        deleteRoute: {
          route: `${biProvidersRouteApi.path}/${biProviderId}`,
          redirect: listBIProvidersRoute.path,
        },
        editRoute: editBIProviderRoute.build({ id: biProviderId }),
      }}
      cardProps={{
        title: 'Visão geral do provedor de dados',
        noData: isBlank(biProvider),
        loading,
      }}
    >
      <InputRead label="Nome" value={biProvider.name} />
    </LayoutShow>
  );
}
