import React, { useCallback } from 'react';
import { Row } from 'react-table';
import { MdRemoveRedEye, MdEdit, MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import { getCurrentTheme } from '../../../../styles/theme';
import Route from '../../../../services/route';
import { useRequest } from '../../../../hooks/RequestContext';

import { Container, IconButton } from './styles';

export interface IActions {
  enable: boolean;
  showRoute?: Route;
  editRoute?: Route;
  deleteRoute?: string;
  row: Row;
  refreshData?: () => void;
}

const Actions: React.FC<IActions> = ({
  showRoute,
  editRoute,
  deleteRoute,
  row,
  refreshData
}) => {
  const { del: requestDel } = useRequest();
  const theme = getCurrentTheme();
  const originalData = (row.original) as any;

  const handleDelete = useCallback(() => {
    const DELETE_CANCELED_MSG = 'DELETE_CANCELED';

    swal(
      'Atenção',
      'Deseja realmente excluir este registro?',
      {
        icon: "warning",
        buttons: [
          "Não",
          {
            text: "Sim",
            icon: "error",
            closeModal: false,
            className: 'sweetalert-btn-confirm-custom'
          },
        ] as any,
      }
    )
    .then((shouldDelete) => {
      if (!shouldDelete) throw new Error(DELETE_CANCELED_MSG);

      return requestDel(`${deleteRoute}/${originalData.id}`)
    })
    .then(() => {
      swal("Oba!", "Item deletado com sucesso.", "success");
      refreshData?.();
    })
    .catch((err) => {
      if (err.message !== DELETE_CANCELED_MSG) {
        swal(
          "Ops, algo deu errado!",
          "Não foi possível remover o registro. Tente novamente mais tarde.",
          "error"
        );
      }
    });
  }, [requestDel, deleteRoute, originalData.id, refreshData]);

  return (
    <Container>
      {!!showRoute && (
        <Link to={showRoute.build({ id: originalData.id })}>
          <IconButton>
            <MdRemoveRedEye color={theme.colors.green} size={23} />
          </IconButton>
        </Link>
      )}

      {!!editRoute && (
        <Link to={editRoute.build({ id: originalData.id })}>
          <IconButton>
            <MdEdit color={theme.colors.blue} size={22} />
          </IconButton>
        </Link>
      )}

      {!!deleteRoute && (
        <IconButton onClick={handleDelete}>
          <MdDelete color={theme.colors.peach} size={22} />
        </IconButton>
      )}
    </Container>
  );
}

export default Actions;
