import React, { useState, useCallback, useRef } from 'react';
import { MdSearch } from 'react-icons/md';
import { TiDeleteOutline } from 'react-icons/ti';

import { useProfileModules } from '../../../../hooks/ProfileModulesContext';

const InputSearch: React.FC = () => {
  const [value, setValue] = useState('');
  const inputRef = useRef<any>(null);
  const { onFilter } = useProfileModules();

  const handleChange = useCallback((e: any) => {
    const newValue = e.target.value;

    setValue(newValue);
    onFilter(newValue);
  }, [onFilter]);

  const handleRemoveFilter = useCallback(() => {
    setValue('');
    onFilter('');

    inputRef.current?.focus();
  }, [onFilter]);

  return (
    <div className="search-container">
      <div className="icon">
        <MdSearch size={21} />
      </div>
      <input
        ref={inputRef}
        placeholder="Pesquisar..."
        value={value}
        onChange={handleChange}
      />

      {!!value && (
        <button className="button" onClick={handleRemoveFilter}>
          <TiDeleteOutline size={22} />
        </button>
      )}
    </div>
  );
}

export default InputSearch;
