import React from 'react';
import { Link } from 'react-router-dom';

import defaultReportImg from '../../../assets/images/default-report.svg';

import { Grid, Container } from './styles';

interface IItem {
  name: string;
  route: string;
  imageUrl: string;
}

const Item: React.FC<IItem> = ({ name, route, imageUrl }) => {
  return (
    <Grid>
      <Container>
        <Link target="_blank" to={route} style={{ maxWidth: 180 }}>
          <div className="image">
            <img src={imageUrl || defaultReportImg} alt="Imagem do módulo"/>
          </div>
          <div className="description">
            <span>{name}</span>
          </div>
        </Link>
      </Container>
    </Grid>
  )
}

export default Item;
