import styled, { css } from 'styled-components';

export type TContainerOption = 'default' | 'success' | 'info' | 'danger';

interface IContainer {
  option: TContainerOption;
}

const defaultButtonCSS = css`
  background-color: ${props => props.theme.colors.primary};
  background-image: linear-gradient(
    to bottom left,
    ${props => props.theme.colors.primary},
    ${props => props.theme.colors.wine},
    ${props => props.theme.colors.primary}
  );
  background-image: -moz-linear-gradient(
    to bottom left,
    ${props => props.theme.colors.primary},
    ${props => props.theme.colors.wine},
    ${props => props.theme.colors.primary}
  );
`;

const successButtonCSS = css`
  height: 40px;
  width: auto;
  padding: 0 16px;
  background-color: ${props => props.theme.colors.green};

  @media(min-width: 600px) {
    min-width: 100px;
  }
`;

const infoButtonCSS = css`
  height: 40px;
  width: auto;
  padding: 0 16px;
  background-color: ${props => props.theme.colors.blue};

  @media(min-width: 600px) {
    min-width: 100px;
  }
`;

const dangerButtonCSS = css`
  height: 40px;
  width: auto;
  padding: 0 16px;
  background-color: ${props => props.theme.colors.peach};

  @media(min-width: 600px) {
    min-width: 100px;
  }
`;

const defaultButtonCSSHover = css`
  &:hover, &:focus, &:active {
    background-color: ${props => props.theme.colors.wine};
    background-image: linear-gradient(
      to bottom left,
      ${props => props.theme.colors.peach},
      ${props => props.theme.colors.wine},
      ${props => props.theme.colors.peach}
    );
    background-image: -moz-linear-gradient(
      to bottom left,
      ${props => props.theme.colors.peach},
      ${props => props.theme.colors.wine},
      ${props => props.theme.colors.peach}
    );
  }
`;

const successButtonCSSHover = css`
  transition: all .15s ease-in-out;

  &:hover, &:focus, &:active {
    box-shadow: 0 7px 12px ${props => props.theme.colors.black_050}, 0 3px 6px ${props => props.theme.colors.black_100};
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
  }
`;

const infoButtonCSSHover = css`
  transition: all .15s ease-in-out;

  &:hover, &:focus, &:active {
    box-shadow: 0 7px 12px ${props => props.theme.colors.black_050}, 0 3px 6px ${props => props.theme.colors.black_100};
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
  }
`;

const dangerButtonCSSHover = css`
  transition: all .15s ease-in-out;

  &:hover, &:focus, &:active {
    box-shadow: 0 7px 12px ${props => props.theme.colors.black_050}, 0 3px 6px ${props => props.theme.colors.black_100};
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
  }
`;

const disableCSS = css`
  background-image: none;
  background-image: none;
  background-color: ${props => props.theme.colors.black_200};
  color: ${props => props.theme.colors.black_400};
  cursor: auto;
`;

function getDefaultButtonCSS(option: TContainerOption, hover?: boolean) {
  switch(option) {
    case 'success':
      return hover ? successButtonCSSHover : successButtonCSS;
    case 'info':
      return hover ? infoButtonCSS : infoButtonCSSHover;
    case 'danger':
      return hover ? dangerButtonCSS : dangerButtonCSSHover;
    default:
      return hover ? defaultButtonCSSHover : defaultButtonCSS;
  }
}

export const Container = styled.button<IContainer>`
  width: 100%;
  height: 51px;
  border-radius: 5px;
  border: none;
  background-size: 210% 210%;
  background-position: 100% 0;
  transition: all 150ms ease;
  box-shadow: none;
  font-weight: 600;
  font-size: 0.9rem;

  ${props => getDefaultButtonCSS(props.option)}
  ${(props) => getDefaultButtonCSS(props.option, true)};
  ${(props) => props.disabled && disableCSS};
`;
