import React, { useState, useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { FiUser, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import InputText from '../../components/Input/Text';
import Button from '../../components/Button';

import { useAuth } from '../../hooks/AuthContext';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';

import mixedCardImg from '../../assets/images/mixed-card.png'
import logoImg from '../../assets/images/logo.png'

import { Section } from './styles';

const currentYear = new Date().getFullYear();

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string().required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });
        const result = await signIn({ username: data.username, password: data.password });

        if (result) {
          history.push('dashboard');
        } else {
          setLoading(false);
        }
      } catch (err) {
        const validationErrors = getValidationsErrors(err);
        formRef.current?.setErrors(validationErrors);

        setLoading(false);
      }
    },
    [signIn, history]
  );

  return (
    <Section className='container'>
      <header>
        <img src={logoImg} alt='Datasim logo' />
        <p>Português</p>
      </header>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className='content'>
          <img src={mixedCardImg} alt='' />
          <h1>log in</h1>

          <div className='input-group'>
            <InputText
              required
              autoFocus
              name='username'
              placeholder='Informe seu usuário'
              startAdornment={() => <FiUser size={21} />}
            />
            <InputText
              required
              name='password'
              type='password'
              placeholder='Informe sua senha'
              gridClassName="password-grid"
              startAdornment={() => <FiLock size={20} />}
            />

            <Button option="default" text='Entrar' disabled={loading} />
          </div>
        </div>
      </Form>
      <footer>
        <p>© {currentYear} Datasim. Todos os direitos reservados.</p>
      </footer>
    </Section>
  );
}

export default SignIn;
