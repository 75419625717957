import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { IBIProvider } from './types';
import { useRequest } from '../../hooks/RequestContext';
import { biProvidersRouteApi } from '../../routes/config/api';
import { listBIProvidersRoute, showBIProviderRoute } from '../../routes/config';
import isBlank from '../../utils/isBlank';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';

import LayoutEdit from '../../components/Layouts/Edit';
import Form from './Form';

interface IEditParams {
  id: string;
}

export const Edit: React.FC = () => {
  const [initialData, setInitialData] = useState<IBIProvider>({} as IBIProvider);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { id: biProviderId } = useParams<IEditParams>();
  const history = useHistory();
  const { get: requestGet, put: requestPut } = useRequest();

  const updateBIProvider = useCallback(
    async (data: any) => {
      try {
        setIsSaving(true);

        const formattedData: any = { name: data.name };

        if (data.account_id) {
          Object.assign(formattedData, {
            powerbi: {
              account_id: data.account_id,
            }
          });
        }

        if (data.auth_client_id) {
          Object.assign(formattedData, {
            powerbi: {
              ...formattedData.powerbi,
              auth_client_id: data.auth_client_id,
            }
          });
        }

        if (data.auth_client_secret) {
          Object.assign(formattedData, {
            powerbi: {
              ...formattedData.powerbi,
              auth_client_secret: data.auth_client_secret,
            }
          });
        }

        const response = await requestPut(
          `${biProvidersRouteApi.path}/${biProviderId}`,
          formattedData
        );

        toast.success("Provedor de dados atualizado com sucesso!");
        history.push(showBIProviderRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível atualizar o provedor de dados!");
      } finally {
        setIsSaving(false);
      }
    },
    [requestPut, biProviderId, history]
  );

  const handleSubmit = useCallback(
    async () => {
      try {
        formRef.current?.setErrors({});

        const data = formRef.current?.getData() as any;
        const schema = Yup.object().shape({
          name: Yup.string().required('Campo obrigatório'),
          account_id: Yup.string(),
          auth_client_id: Yup.string(),
          auth_client_secret: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        updateBIProvider(data);
      } catch (err) {
        const validationErrors = getValidationsErrors(err);
        formRef.current?.setErrors(validationErrors);
      }
    },
    [updateBIProvider]
  );

  useEffect(() => {
    async function findBIProvider() {
      try {
        const response = await requestGet(`${biProvidersRouteApi.path}/${biProviderId}`);
        if (!isBlank(response)) {
          setInitialData({
            ...response,
            profile: {
              label: response.profile?.name,
              value: response.profile?.id,
            }
          });
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findBIProvider();
  }, [requestGet, biProviderId]);

  return (
    <LayoutEdit
      breadcrumbs={[
        { text: 'Provedores de Dados', path: listBIProvidersRoute.path },
        { text: 'Editar Provedor de Dados' }
      ]}
      cardProps={{
        title: 'Alterar informações do provedor de dados',
        noData: isBlank(initialData),
        loading,
      }}
      footerActionsProps={{
        onCancelRoute: listBIProvidersRoute.path,
        onSubmit: handleSubmit,
        loading: isSaving || loading,
      }}
    >
      <Form
        bi_provider_id={parseInt(biProviderId)}
        formRef={formRef}
        initialData={initialData}
      />
    </LayoutEdit>
  );
}
