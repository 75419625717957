import React from 'react';

import Grid from "../../../components/Grid";
import defaultImageUrl from '../../../assets/images/upload-module-image.svg';

import { Container } from './styles';

interface IImagePreview {
  imageUrl: string;
}

const ImagePreview: React.FC<IImagePreview> = ({ imageUrl }) => (
  <Grid isItem xs={6} spacing={2}>
    <Container>
      <div className="content">
        <img
          src={imageUrl || defaultImageUrl}
          alt="Imagem do módulo"
        />

        {!imageUrl && <caption>Imagem padrão</caption>}
      </div>
    </Container>
  </Grid>
);

export default ImagePreview;
