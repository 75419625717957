import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .label {
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0 0 4px 5px;
  }

  .error {
    font-size: 0.7rem;
    font-weight: 500;
    padding: 4px 0 0 5px;
    color: ${props => props.theme.colors.red};
  }
`;
