import React from 'react';
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';

import { getCurrentTheme } from './styles/theme';
import Routes from './routes';
import { AuthProvider } from './hooks/AuthContext';

import GlobalStyle from './styles/global';

const theme = getCurrentTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastContainer />
      <Router>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
