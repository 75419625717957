import React, { useState, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useRequest } from '../../hooks/RequestContext';
import { profilesRouteApi } from '../../routes/config/api';
import { listProfilesRoute, showProfileRoute } from '../../routes/config';
import getValidationsErrors from '../../utils/errors/getValidationsErrors';

import LayoutCreate from '../../components/Layouts/Create';

import Form from './Form';

export const Create: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { post: requestPost } = useRequest();
  const history = useHistory();

  const createProfile = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const formattedData = {
          name: data.name,
          can_manage_users: data.can_manage_users,
          can_manage_modules: data.can_manage_modules,
          can_manage_profiles: data.can_manage_profiles,
          can_manage_bi_providers: data.can_manage_bi_providers,
          profile_modules: data.modules.map((moduleId: number) => ({
            module_id: moduleId,
          })),
        }
        const response = await requestPost(profilesRouteApi.path, formattedData);

        toast.success("Perfil criado com sucesso!");
        history.push(showProfileRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível cadastrar este perfil!");
      } finally {
        setLoading(false);
      }
    },
    [requestPost, history]
  );

  const handleSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;
      const schema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório'),
        modules: Yup.mixed().required('Campo obrigatório'),
      });

      await schema.validate(
        {
          ...data,
          modules: data.modules?.length ? data.modules : null
        },
        { abortEarly: false }
      );

      createProfile(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [createProfile]);

  return (
    <LayoutCreate
      breadcrumbs={[
        { text: 'Perfis', path: listProfilesRoute.path },
        { text: 'Criar Perfil' }
      ]}
      cardProps={{
        title: 'Criar novo perfil',
        noData: false,
        loading: false,
      }}
      footerActionsProps={{
        onCancelRoute: listProfilesRoute.path,
        onSubmit: handleSubmit,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutCreate>
  );
}
