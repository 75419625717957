import React from "react";

import Table, { ITableProps } from "../../Table";
import LayoutBase, { IBaseLayoutProps } from "../Base";

interface IListLayoutProps extends IBaseLayoutProps {
  tableProps: ITableProps;
}

const List: React.FC<IListLayoutProps> = ({
  breadcrumbs,
  navMenuProps,
  tableProps,
  children,
}) => {
  return (
    <LayoutBase
      breadcrumbs={breadcrumbs}
      navMenuProps={navMenuProps}
    >
      <Table {...tableProps} />

      {children}
    </LayoutBase>
  );
};

export default List;
