import React from 'react';

import Header from './Header';

import { Container, TopBorder, Content } from './styles';

const DrawerApp: React.FC = ({ children }) => (
  <Container>
    <TopBorder />
    <Header />
    <Content>
      {children}
    </Content>
  </Container>
);

export default DrawerApp;
