import styled from 'styled-components';
import { Form as UForm } from '@unform/web';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .table-container {
    padding: 0 16px 32px 16px;
  }
`;

export const Form = styled(UForm)`
  width: 100%;
`;
