import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 60px;
  transition: padding-left 250ms ease-in-out;

  @media (max-width: 800px) {
    padding-left: 0;
  }
`;

export const TopBorder = styled.div`
  position: fixed;
  height: 2px;
  width: 100%;
  background-color: ${props => props.theme.colors.primary};
  z-index: 3000;
`;
