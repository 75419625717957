import React from 'react';

import { biProvidersRouteApi } from '../../routes/config/api';
import {
  showBIProviderRoute,
  editBIProviderRoute,
  createBIProviderRoute
} from '../../routes/config';

import LayoutList from '../../components/Layouts/List';

export const List: React.FC = () => {
  return (
    <LayoutList
      breadcrumbs={[{ text: 'Provedores de Dados' }]}
      navMenuProps={{
        visible: true,
        newRoute: createBIProviderRoute.path,
      }}
      tableProps={{
        title: 'Listagem de Provedores de Dados',
        actions: {
          enable: true,
          showRoute: showBIProviderRoute,
          editRoute: editBIProviderRoute,
          deleteRoute: biProvidersRouteApi.path,
        },
        columns: [
          { Header: 'Nome', accessor: 'name', minWidth: 300},
        ],
        fetch: {
          url: biProvidersRouteApi.path,
        }
      }}
    />
  );
}
