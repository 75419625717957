import {
  listBIProvidersRoute,
  listUsersRoute,
  listModulesRoute,
  listProfilesRoute,
  listCompaniesRoute,
} from '../../routes/config';

interface IAdminModulePaths {
  can_manage_bi_providers: boolean;
  can_manage_modules: boolean;
  can_manage_profiles: boolean;
  can_manage_users: boolean;
  can_manage_companies: boolean;
}

export const getAdminModulePaths = (permissions: IAdminModulePaths): string[] => {
  const noPermissions =
    !permissions.can_manage_bi_providers &&
    !permissions.can_manage_users &&
    !permissions.can_manage_modules &&
    !permissions.can_manage_profiles;

  if (noPermissions) return [];

  const adminModules: string[] = [];

  if (permissions.can_manage_companies) {
    adminModules.push(listCompaniesRoute.path);
  }

  if (permissions.can_manage_bi_providers) {
    adminModules.push(listBIProvidersRoute.path);
  }

  if (permissions.can_manage_users) {
    adminModules.push(listUsersRoute.path);
  }

  if (permissions.can_manage_modules) {
    adminModules.push(listModulesRoute.path);
  }

  if (permissions.can_manage_profiles) {
    adminModules.push(listProfilesRoute.path);
  }

  return adminModules;
};
