import React from "react";
import { Switch } from "react-router-dom";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import { Create, Edit, List, Show } from "../../pages/BIProviders";

export const createBIProviderRoute = new Route("/provedor-de-dados/novo");

export const editBIProviderRoute = new Route("/provedor-de-dados/:id/editar");

export const listBIProvidersRoute = new Route("/provedor-de-dados");

export const showBIProviderRoute = new Route("/provedor-de-dados/:id/visualizar");

export const BIProvidersRouteComponent: React.FC = () => (
  <Switch>
    <RouteWrapper
      exact
      isPrivate
      path={createBIProviderRoute.path}
      component={Create}
    />
    <RouteWrapper exact isPrivate path={editBIProviderRoute.path} component={Edit} />
    <RouteWrapper exact isPrivate path={listBIProvidersRoute.path} component={List} />
    <RouteWrapper exact isPrivate path={showBIProviderRoute.path} component={Show} />

    <RouteWrapper
      isPrivate
      redirectIfNotExact
      path={listBIProvidersRoute.path}
      component={List}
    />
  </Switch>
);
