import React from "react";

import LayoutBase, { IBaseLayoutProps } from "../Base";
import CardForm, { ICardFormProps } from "../../CardForm";
import FooterActions, {
  FooterActionsEmpty,
  IFooterActionsProps
} from "../../FooterActions";

interface ILayoutShowProps extends Omit<IBaseLayoutProps, 'navMenuProps'> {
  cardProps: ICardFormProps;
  footerActionsProps: IFooterActionsProps;
}

const Create: React.FC<ILayoutShowProps> = ({
  breadcrumbs,
  cardProps,
  children,
  footerActionsProps,
}) => {
  return (
    <LayoutBase
      breadcrumbs={breadcrumbs}
      navMenuProps={{
        visible: false,
      }}
    >
      <CardForm {...cardProps}>
        {children}
      </CardForm>

      <FooterActions {...footerActionsProps} />
      <FooterActionsEmpty />
    </LayoutBase>
  );
};

export default Create;
