import React, { useState, useEffect, useCallback } from 'react';
import { useTable, useFlexLayout, usePagination, Column } from 'react-table';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';

import { useRequest } from '../../hooks/RequestContext';

import ResponsiveTable from './ResponsiveTable';
import Actions, { IActions } from './ResponsiveTable/Actions';
import Pagination from './Pagination';

import { Container, Header } from './styles';

export type TTableColumn = Column;

interface IFetch {
  url: string;
}

export interface ITableProps {
  title: string;
  columns: TTableColumn[];
  fetch: IFetch;
  actions: Omit<IActions, 'row'>;
}

const Table: React.FC<ITableProps> = ({ title, columns, fetch, actions }) => {
  const [data, setData] = useState<any[]>([]);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const tableColumns: any = React.useMemo(
    () => {
      const actionsColumn: Column = {
        Header: 'Ações',
        accessor: 'actions',
        width: 150,
        Cell: ({ row }) => <Actions {...actions} row={row} refreshData={findData} />,
      };

      return actions.enable ? [...columns, actionsColumn] : columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, columns],
  );
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    getTableBodyProps,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: tableColumns,
      data,
      // @ts-ignore
      initialState: { pageSize: 5 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFlexLayout,
    usePagination,
  );
  const { get: requestGet } = useRequest();

  const findData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await requestGet(
        `${fetch.url}?page=${pageIndex}&per_page=${pageSize}`
      );
      const totalPages = Math.ceil(response.meta.total / pageSize);

      setData(response.data);
      setControlledPageCount(totalPages);
    } catch {
      toast.error('Ops, ocorreu um problema ao buscar as informações!');
    } finally {
      setLoading(false);
    }
  }, [requestGet, pageIndex, fetch.url, pageSize]);

  useEffect(() => {
    findData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  return (
    <Container>
      <Header>
        <h4>{title}</h4>
      </Header>
      <ResponsiveTable
        tableProps={getTableProps()}
        headerGroups={headerGroups}
        rows={rows}
        tableBodyProps={getTableBodyProps()}
        prepareRow={prepareRow}
        enableActions={actions.enable}
        isLoading={loading}
      />

      {loading && (
        <div className="loading">
          <ReactLoading type="bars" className="spinner" width={38} />
        </div>
      )}

      <Pagination
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
      />
    </Container>
  );
}

export default Table;
