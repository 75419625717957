import React from 'react';
import { FormHandles } from '@unform/core';

import { profilesRouteApi } from '../../../routes/config/api';
import { IUser } from '../types';

import Grid from '../../../components/Grid';
import InputText from '../../../components/Input/Text';
import InputAsyncSelect from '../../../components/Input/AsyncSelect';

import { Form } from '../styles';

interface IFormProps {
  initialData?: IUser;
  formRef: React.RefObject<FormHandles>;
}

const CustomForm: React.FC<IFormProps> = ({
  formRef,
  initialData,
}) => {
  React.useEffect(() => {
    if (initialData) {
      formRef.current?.setData(initialData);
    }
  }, [initialData, formRef]);

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <Grid isContainer>
        <InputText
          required
          label="Nome"
          name="name"
        />
        <InputText
          required
          label="Usuário"
          name="username"
          xs={6}
        />
        <InputText
          label="E-mail"
          name="email"
          xs={6}
        />
        <InputText
          required
          label="Usuário do Power BI"
          name="powerbi_user"
          xs={6}
        />
        <InputAsyncSelect
          required
          name="profile"
          label="Perfil"
          placeholder="Selecione um perfil"
          fetchURL={profilesRouteApi.path}
          xs={6}
        />
        <InputText
          required
          type="password"
          label="Senha"
          name="password"
          xs={6}
        />
        <InputText
          required
          type="password"
          label="Senha de Confirmação"
          name="password_confirmation"
          xs={6}
        />
      </Grid>
    </Form>
  );
}

export default CustomForm;
