import styled from "styled-components";

import logoImg from '../assets/images/logo.png'

export const Main = styled.main`
  display: flex;
  flex: 1;
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.backgrond};
`;

export const AnimatedLoading = styled.img.attrs({
  src: logoImg,
})`
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  width: 230px;
  object-fit: contain;

  transform: scale(1);
  animation: pulse 1200ms infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.85);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.85);
    }
  }

  @media(max-width: 800px) {
    width: 200px;
  }
`;
