import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import CardForm from '../../components/CardForm';
import InputRead from '../../components/Input/Read';
import Breadcrumbs from "../../components/Breadcrumbs";

import { useRequest } from '../../hooks/RequestContext';
import { myCompanyRouteApi } from '../../routes/config/api';
import isBlank from '../../utils/isBlank';
import { formatCNPJ } from '../../utils/formatCNPJ';
import { formatPhone } from '../../utils/formatPhone';
import { useBreadcrumbs } from '../../hooks/BreadcrumbsContext';
import { dashboardRoute } from '../../routes/config';

import { Container } from './styles';

interface ICompany {
  id: string;
  cnpj: string;
  company_name: string;
  trading_name: string;
  email: string;
  phone: string;
}

const UserProfile: React.FC = () => {
  const [company, setCompany] = useState({} as ICompany);
  const [loading, setLoading] = useState(false);
  const { get: requestGet } = useRequest();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    async function findUser() {
      try {
        setLoading(true);

        const response = await requestGet(myCompanyRouteApi.path);
        if (!isBlank(response)) {
          setCompany({
            ...response,
            cnpj: formatCNPJ(response.cnpj),
            phone: formatPhone(response.phone),
          });
        }
      } catch {
        toast.error('Ops, ocorreu um problema. Tente novamente mais tarde!');
      } finally {
        setLoading(false);
      }
    }

    findUser();
  }, [requestGet]);

  useEffect(() => {
    setBreadcrumbs([
      { text: 'Ínicio', path: dashboardRoute.path },
      { text: 'Minha Empresa' }
    ]);
  }, [setBreadcrumbs]);

  return (
    <Container>
      <Breadcrumbs className="breadcrumbs" />
      <CardForm
        title="Minha empresa"
        noData={isBlank(company)}
        loading={loading}
      >
        <InputRead label="CNPJ" value={company.cnpj} />
        <InputRead label="Razão Social" value={company.company_name} />
        <InputRead label="Nome Fantasia" value={company.trading_name} />
        <InputRead label="E-mail" value={company.email} xs={6} />
        <InputRead label="Telefone" value={company.phone} xs={6} />
      </CardForm>
    </Container>
  );
}

export default UserProfile;
