export const modulePermissions = [
  { label: "Permissão para Leitura", value: 0 },
  { label: "Permissão para Leitura/Escrita", value: 1 },
  { label: "Permissão para Cópia", value: 2 },
  { label: "Permissão para Criar", value: 4 },
  { label: "Permissão Total", value: 7 },
];

export function formatModulePermission(value: number): string {
  const [defaultPermission] = modulePermissions;
  const selectedPermission = modulePermissions.find(
    permission => permission.value === value
  );

  return selectedPermission?.label || defaultPermission.label;
}
