import React, { useState, createContext, useContext, useEffect, useCallback } from 'react';
import uuidByString from 'uuid-by-string';
import Fuse from 'fuse.js'

import { useAuth } from '../AuthContext';
import { useRequest } from '../RequestContext';
import { userProfileRouteApi } from '../../routes/config/api';
import { myCompanyRoute } from '../../routes/config';
import { isRootUser, isOwnerUser } from '../../utils/userTypes';
import { getAdminModulePaths } from './getAdminModulePaths';
import { dynamicSort } from '../../utils/dynamicSort';

export interface IModule {
  id: number;
  name: string;
  image_url?: string;
  route: string;
}

export interface IProfileModulesProps {
  loading: boolean;
  modules: IModule[];
  modulesPath: string[];
  adminModulesPath: string[];
  onFilter(value: string): void;
}

const ProfileModulesContext = createContext<IProfileModulesProps>({} as IProfileModulesProps);

export const ProfileModulesProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState<IModule[]>([]);
  const [defaultModules, setDefaultModules] = useState<IModule[]>([]);
  const [modulesPath, setModulesPath] = useState<string[]>([]);
  const [adminModulesPath, setAdminModulesPath] = useState<string[]>([]);
  const { signed, setUser } = useAuth();
  const { get: requestGet } = useRequest();

  const findProfileModules = useCallback(async () => {
    try {
      const response = await requestGet(userProfileRouteApi.path);
      const profile = response.profile;
      const formattedUser = {
        ...response.user,
        profile
      };
      const profileModules = response.profile_modules;
      const newProfileModules: IModule[] = [];
      const canManageCompanies = isRootUser(response.user.type);
      const userAdminModulePaths = getAdminModulePaths({
        can_manage_bi_providers: profile.can_manage_bi_providers,
        can_manage_users: profile.can_manage_users,
        can_manage_modules: profile.can_manage_modules,
        can_manage_profiles: profile.can_manage_profiles,
        can_manage_companies: canManageCompanies,
      });
      const newModulesPath: string[] = [];

      if (canManageCompanies || isOwnerUser(response.user.type)) {
        userAdminModulePaths.push(myCompanyRoute.path);
      }

      for (const profileModule of profileModules) {
        const removeSpecialCharactersRegEx = /[^.\w]/gi;
        const formattedName = profileModule.module.name
          .toLowerCase()
          .replace(removeSpecialCharactersRegEx, '');
        const uuidByName = uuidByString(formattedName);
        const route = `/relatorio/${uuidByName}/${profileModule.module.id}`;

        newProfileModules.push({
          id: profileModule.module.id,
          name: profileModule.module.name,
          image_url: profileModule.module.image_url,
          route,
        });
        newModulesPath.push(route);
      }

      newProfileModules.sort(dynamicSort('name', 'ASC'));

      setUser(formattedUser);
      setModulesPath(newModulesPath);
      setAdminModulesPath(userAdminModulePaths);
      setModules(newProfileModules);
      setDefaultModules(newProfileModules);
    } catch {}
    finally {
      setLoading(false);
    }
  }, [requestGet, setUser]);

  const onFilter = useCallback((value: string) => {
    if (!value) {
      setModules(defaultModules);
      return;
    }

    const fuse = new Fuse(defaultModules, {
      includeScore: true,
      threshold: 0.3,
      keys: ['name'],
    });
    const newModules = fuse
      .search(value)
      .map((result) => result.item);

    setModules(newModules);
  }, [defaultModules]);

  useEffect(() => {
    if (signed) findProfileModules();
  }, [signed, findProfileModules]);

  return (
    <ProfileModulesContext.Provider
      value={{
        loading,
        modules,
        modulesPath,
        adminModulesPath,
        onFilter
      }}
    >
      {children}
    </ProfileModulesContext.Provider>
  );
};

export const useProfileModules = (): IProfileModulesProps => {
  const context = useContext(ProfileModulesContext);

  if (!context) {
    throw new Error('useProfileModules must be used within a ProfileModulesProvider');
  }

  return context;
};

export default ProfileModulesContext;
