import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* Adjust content with other table data columns */
  padding-top: 1px;
`;

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 4px;

  :hover, &:active {
    -webkit-tap-highlight-color: transparent;
    background-color: ${props => props.theme.colors.black_100};
  }
`;
