import React, { useState, useEffect, useCallback } from 'react';
import { FormHandles } from '@unform/core';

import { IProfile } from '../types';
import { useRequest } from '../../../hooks/RequestContext';

import Grid from '../../../components/Grid';
import InputText from '../../../components/Input/Text';
import Checkbox from '../../../components/Input/Checkbox';
import Select from '../../../components/Input/Select';

import { Form } from '../styles';
import { modulesRouteApi } from '../../../routes/config/api';

interface IFormProps {
  initialData?: IProfile;
  formRef: React.RefObject<FormHandles>;
}

const CustomForm: React.FC<IFormProps> = ({
  formRef,
  initialData,
}) => {
  const [modules, setModules] = useState<any[]>([]);
  const { get: requestGet } = useRequest();

  const findModules = useCallback(async () => {
    const myModulesResponse = await requestGet(`${modulesRouteApi.path}?per_page=1000`);
    const formattedModules = myModulesResponse.data.map((module: any) => ({
      label: module.name,
      value: module.id,
    }));
    const modulesSharedWithMeResponse = await requestGet(`${modulesRouteApi.path}/shared-with-me?per_page=1000`);
    const formattedModulesSharedWithMeResponse = modulesSharedWithMeResponse.data.map((module: any) => ({
      label: module.name,
      value: module.id,
    }));

    setModules([...formattedModules, ...formattedModulesSharedWithMeResponse]);
  }, [requestGet]);

  useEffect(() => {
    if (initialData) {
      formRef.current?.setData(initialData);
    }
  }, [initialData, formRef]);

  useEffect(() => {
    findModules()
  }, [findModules]);

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <Grid isContainer>
        <InputText
          required
          label="Nome"
          name="name"
        />

        <Select
          required
          isMulti
          closeMenuOnSelect={false}
          name="modules"
          label="Selecione os módulos"
          options={modules}
        />

        <Checkbox
          name="can_manage_users"
          label="Permissão para gerenciar usuários"
          xs={6}
        />
        <Checkbox
          name="can_manage_modules"
          label="Permissão para gerenciar módulos"
          xs={6}
        />
        <Checkbox
          name="can_manage_profiles"
          label="Permissão para gerenciar perfis"
          xs={6}
        />
        <Checkbox
          name="can_manage_bi_providers"
          label="Permissão para gerenciar provedores de dados"
          xs={6}
        />
      </Grid>
    </Form>
  );
}

export default CustomForm;
