interface IThemeColors {
  primary: string;
  secundary: string;
  backgrond: string;
  peach: string;
  wine: string;
  white: string;
  red: string;
  green: string;
  blue: string;
  black: string;
  black_900: string;
  black_800: string;
  black_700: string;
  black_600: string;
  black_500: string;
  black_400: string;
  black_300: string;
  black_200: string;
  black_150: string;
  black_100: string;
  black_050: string;
  text: {
    title: string;
    p: string;
    light: string;
    cream: string;
  }
}

export interface ITheme {
  colors: IThemeColors;
}

interface IThemeOptions {
  light: ITheme;
  dark?: ITheme;
}

type TThemeOptions = 'light' | 'dark'

const theme: IThemeOptions = {
  light: {
    colors: {
      primary: '#fd951f',
      secundary: '#5a4799',
      backgrond: '#f5f6fa',
      peach: '#ff8d72',
      wine: '#ff6491',
      white: '#ffffff',
      red: '#c35355',
      green: '#4ab46e',
      blue: '#00CBCC',
      black: '#000000',
      black_900: 'rgba(0, 0, 0, 0.9)',
      black_800: 'rgba(0, 0, 0, 0.8)',
      black_700: 'rgba(0, 0, 0, 0.7)',
      black_600: 'rgba(0, 0, 0, 0.6)',
      black_500: 'rgba(0, 0, 0, 0.5)',
      black_400: 'rgba(0, 0, 0, 0.4)',
      black_300: 'rgba(0, 0, 0, 0.3)',
      black_200: 'rgba(0, 0, 0, 0.2)',
      black_100: 'rgba(0, 0, 0, 0.1)',
      black_150: 'rgba(0, 0, 0, 0.15)',
      black_050: 'rgba(0, 0, 0, 0.05)',
      text: {
        title: '#29292e',
        p: '#9a9a9a',
        light: '#ffffff',
        cream: '#666666',
      }
    }
  }
}

export const getCurrentTheme = (): ITheme => {
  const currentTheme = localStorage.getItem("@App:theme") as TThemeOptions;
  return theme[currentTheme] || theme.light;
}
